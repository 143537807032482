import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { db } from "../../firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { update } from "../../context/userSlice.js";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../assets/gcb.png";
const theme = createTheme();

export default function SignIn() {
  // const clientName = useSelector((state) => state.user.clientName);
  const [bookBalance, setBookBalance] = useState("");
  const [availableBalance, setAvailableBalance] = useState("");
  const [accountStatus, setAccountStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [detailsArray, setDetailsArray] = useState([]);
  const getDetails = collection(db, "clientInfo");

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const q = query(
    getDetails,
    where("password", "==", password),
    where("routingNumber", "==", routingNumber)
  );

  useEffect(() => {
    const fetchData2 = () => {
      if (detailsArray.length == 0) {
        // console.log("errorlogg");
      }
    };
    return () => {
      fetchData2();
    };
  }, [detailsArray]);

  const fetchData = async () => {
    let list = [];
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setDetailsArray(doc.data());
        dispatch(
          update({
            clientName: doc.data().clientName,
            routingNumber: doc.data().routingNumber,
            cardBalance: doc.data().cardBalance,
            availableBalance: doc.data().availableBalance,
            accountStatus: doc.data().accountStatus,
            picUrl: doc.data().picUrl,
            sex: doc.data().sex,
            emailAddress: doc.data().emailAddress,
            phoneNumber: doc.data().phoneNumber,
            country: doc.data().country,
            dob: doc.data().dob,
            maritalStatus: doc.data().maritalStatus,
            errorMsg: doc.data().errorMsg,
            loggedIn: true,
            blocked: doc.data().blocked,
          })
        );
        navigate("/dashboard");
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      fetchData();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <a href="/">
            {" "}
            <img src={logo} alt="logo" style={{ width: "200px" }} />
          </a>
          <br /> <br />
          {/* <Typography component="h1" variant="h5">
            Sign in
          </Typography> */}
          <Box
            component="form"
            // onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              type="text"
              margin="normal"
              required
              fullWidth
              id="routingNumber"
              label="Routing Number"
              name="routingNumber"
              autoComplete="routingNumber"
              autoFocus
              value={routingNumber}
              onChange={(e) => setRoutingNumber(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              type="text"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox value="remember" style={{ color: "#002e6d" }} />
              }
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
              style={{ backgroundColor: "#002e6d" }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/" variant="body2" style={{ color: "#002e6d" }}>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
