import React, { useState } from "react";

import { db, storage } from "../../../firebase";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import {
  Grid,
  Card,
  Button,
  Avatar,
  Badge,
  styled,
  Container,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Modal,
  Fade,
  Box,
  Backdrop,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Slide from "react-reveal/Slide";
import { useUserAuth } from "../../../UserAuthContext.js";
import emailjs from "@emailjs/browser";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function CreateAccountHome() {
  const { user, logOut } = useUserAuth();
  const [selectedImages, setSelectedImages] = useState(null);
  const [imageUpload, setImageUpload] = useState(null);
  const [clientName, setClientName] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [password, setPassword] = useState("");
  const [sex, setSex] = useState("");
  const [accountBalance, setAccountBalance] = useState("");
  const [cardBalance, setCardBalance] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [dob, setDob] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [accountStatus, setAccountStatus] = useState("");
  const [imfCode, setImfCode] = useState("");

  const [lTName, setLtName] = useState(""); //LT means Last transaction
  const [ltDate, setLtDate] = useState("");
  const [ltAmount, setLtAmount] = useState("");
  const [ltType, setLTtype] = useState("");
  const handleLTtype = (event) => {
    setLTtype(event.target.value);
  };

  const [stName, setStName] = useState(""); //ST means second  to Last transaction
  const [stDate, setStDate] = useState("");
  const [stAmount, setStAmount] = useState("");
  const [stType, setSTtype] = useState("");
  const handleSTtype = (event) => {
    setSTtype(event.target.value);
  };

  const colRef = collection(db, "clientInfo");

  //modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //for sex select button
  const handleSexChange = (event) => {
    setSex(event.target.value);
  };

  //for sex select button
  const handleAccountStatus = (event) => {
    setAccountStatus(event.target.value);
  };
  //for account status select button
  const handleMaritalStatus = (event) => {
    setMaritalStatus(event.target.value);
  };

  const onSelectedFile = (event) => {
    setImageUpload(event.target.files[0]);
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const imageArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectedImages(imageArray);
  };

  const sentEmail = (e) => {
    emailjs
      .send(
        "service_nwgx1se",
        "template_fpmdv5c",
        toSend,
        "user_6ixfEQSmxORDZ3B2xwHU6"
      )
      .then((response) => {})
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const [toSend, setToSend] = useState({
    admin: user.email,
  });

  const handleInputUpload = (url) => {
    if (
      //   imageUrl === "" ||
      clientName === "" ||
      routingNumber === "" ||
      password === "" ||
      sex === "" ||
      accountBalance === "" ||
      cardBalance === "" ||
      emailAddress === "" ||
      phoneNumber === "" ||
      country === "" ||
      dob === "" ||
      maritalStatus === "" ||
      accountStatus === ""
    ) {
      alert("Please insert required fields");
    } else {
      addDoc(colRef, {
        admin: user.email,
        clientName: clientName,
        routingNumber: routingNumber,
        password: password,
        sex: sex,
        availableBalance: accountBalance,
        cardBalance: cardBalance,
        errorMsg: errorMsg,
        emailAddress: emailAddress,
        phoneNumber: phoneNumber,
        dob: dob,
        phoneNumber: phoneNumber,
        country: country,
        maritalStatus: maritalStatus,
        accountStatus: accountStatus,
        picUrl: url,
        blocked: false,
        lTName: lTName,
        ltDate: ltDate,
        ltAmount: ltAmount,
        ltType: ltType,
        stName: stName,
        stDate: stDate,
        stAmount: stAmount,
        stType: stType,
        imfCode: "00001",
        timestamp: Timestamp.now(),
      }).then((snapshot) => {
        // console.log(toSend);
        handleOpen();
      });

      setClientName("");
      setSex("");
      setAccountBalance("");
      setCardBalance("");
      setErrorMsg("");
      setEmailAddress("");
      setPhoneNumber("");
      setDob("");
      setMaritalStatus("");
      setCountry("");
      setAccountStatus("");
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    const imageRef = ref(storage, `profiles/${imageUpload.name}`);

    const uploadTask = uploadBytes(imageRef, imageUpload).then(() => {
      getDownloadURL(imageRef)
        .then((url) => {
          handleInputUpload(url);
          sentEmail();
        })
        .catch((error) => {
          console.log(error.message, "error getting url");
        });
    });
  };

  return (
    <Slide left duration={2000}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Tracking Created Successfully</Modal.Title>
        </Modal.Header> */}
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Account Created successfully
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Routing Number : {routingNumber}
              <br />
              password : {password}
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        sx={{ mt: 1.5 }}
        className="account"
      >
        <Grid item xs={4} direction={{ xs: "row", sm: "row" }}>
          <Grid xs={12} className="profileInfoWrap">
            <Card
              style={{
                display: "flex",
                // justifyContent: "space-between",
                color: "#344767",
                alignItems: "baseline",
                boxShadow: "none",
                flexDirection: "column",
              }}
            >
              <div className=" profileInfoImg">
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  {selectedImages &&
                    selectedImages.map((image, index) => {
                      return (
                        <Avatar
                          alt="Remy Sharp"
                          src={image}
                          key={image}
                          sx={{
                            width: 150,
                            height: 150,
                            border: "5px solid #f1f1f1",
                          }}
                        />
                      );
                    })}
                </StyledBadge>
              </div>

              <div className="profileInfoContent">
                <input
                  type="file"
                  name="images"
                  onChange={onSelectedFile}
                  accept="image/png, image/jpeg, image/webp"
                />
              </div>
            </Card>
          </Grid>

          {/* <Grid xs={12}>
       
          </Grid> */}
        </Grid>

        <Grid item xs={8} className="profile">
          <Card
            sx={{
              //   height: 60 + "vh",
              boxShadow: "none",

              marginBottom: "0px",
            }}
          >
            <Container>
              <h4>My Profile</h4>
            </Container>
            <Container>
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <span>Full Name</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>Routing Number</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    helperText="10 digit number"
                    name="routingNumber"
                    value={routingNumber}
                    onChange={(e) => {
                      setRoutingNumber(e.target.value);
                      setToSend({
                        ...toSend,
                        [e.target.name]: [e.target.value],
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <span>Password</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    helperText="for login"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>Sex</span>
                  <br />
                  <FormControl sx={{ minWidth: 100 }} fullWidth>
                    <Select
                      value={sex}
                      onChange={handleSexChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <span>Account Balance</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    helperText="without $"
                    value={accountBalance}
                    onChange={(e) => setAccountBalance(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>Card Balance</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    helperText="without $"
                    value={cardBalance}
                    onChange={(e) => setCardBalance(e.target.value)}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <span>Error Message</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    helperText="for Billing"
                    value={errorMsg}
                    onChange={(e) => setErrorMsg(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>Email Address</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    // helperText="without $"
                  />
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <span>Phone Number</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    // helperText="for Billing"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>Country </span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    // helperText="without $"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <span>D.O.B</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    helperText="Date of birth"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>Marital Status</span>
                  <br />
                  <FormControl sx={{ minWidth: 100 }} fullWidth>
                    <Select
                      value={maritalStatus}
                      onChange={handleMaritalStatus}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={"Not Set"}>Not Set</MenuItem>
                      <MenuItem value={"Single"}>Single</MenuItem>
                      <MenuItem value={"Married"}>Married</MenuItem>
                      <MenuItem value={"In Relationship"}>
                        In Relationship
                      </MenuItem>
                      <MenuItem value={"Divorced"}>Divorced</MenuItem>
                      <MenuItem value={"Separated"}>Separated</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>{" "}
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <span>Account Status</span>
                  <br />
                  <FormControl sx={{ minWidth: 100 }} fullWidth>
                    <Select
                      value={accountStatus}
                      onChange={handleAccountStatus}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={"On Hold"}>On Hold</MenuItem>
                      <MenuItem value={"Active"}>Active</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} md={6}>
                  <span>Transaction History</span>
                  <br />
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Last Transaction</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          required
                          id="outlined-required"
                          fullWidth
                          // helperText="Date of birth"
                          placeholder="Name"
                          value={lTName}
                          onChange={(e) => setLtName(e.target.value)}
                        />{" "}
                        <TextField
                          required
                          id="outlined-required"
                          fullWidth
                          // helperText="Date"
                          value={ltDate}
                          placeholder="Date"
                          onChange={(e) => setLtDate(e.target.value)}
                        />{" "}
                        <TextField
                          required
                          id="outlined-required"
                          fullWidth
                          placeholder="Amount"
                          // helperText="Date of birth"
                          value={ltAmount}
                          onChange={(e) => setLtAmount(e.target.value)}
                        />{" "}
                        <FormControl sx={{ minWidth: 100 }} fullWidth>
                          <Select
                            value={ltType}
                            onChange={handleLTtype}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value={"Credit"}>Credit</MenuItem>
                            <MenuItem value={"Debit"}>Debit</MenuItem>
                            <MenuItem value={"Pending"}>Pending</MenuItem>
                          </Select>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Second to the last Transaction</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          required
                          id="outlined-required"
                          fullWidth
                          // helperText="Date of birth"
                          placeholder="Name"
                          value={stName}
                          onChange={(e) => setStName(e.target.value)}
                        />{" "}
                        <TextField
                          required
                          id="outlined-required"
                          fullWidth
                          // helperText="Date"
                          value={stDate}
                          placeholder="Date"
                          onChange={(e) => setStDate(e.target.value)}
                        />{" "}
                        <TextField
                          required
                          id="outlined-required"
                          fullWidth
                          placeholder="Amount"
                          // helperText="Date of birth"
                          value={stAmount}
                          onChange={(e) => setStAmount(e.target.value)}
                        />{" "}
                        <FormControl sx={{ minWidth: 100 }} fullWidth>
                          <Select
                            value={stType}
                            onChange={handleSTtype}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value={"Credit"}>Credit</MenuItem>
                            <MenuItem value={"Debit"}>Debit</MenuItem>
                            <MenuItem value={"Pending"}>Pending</MenuItem>
                          </Select>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                    {/* <Accordion disabled>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography>Disabled Accordion</Typography>
                    </AccordionSummary>
                  </Accordion> */}
                  </div>
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    onClick={handleUpload}
                    // onClick={handleOpen}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Card>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default CreateAccountHome;
