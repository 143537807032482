import React, { PureComponent, useCallback, useState } from "react";
import {
  MdVerified,
  MdManageAccounts,
  MdLock,
  MdAccountCircle,
  MdOutlineNoAccounts,
} from "react-icons/md";
import "./account.scss";
import {
  Grid,
  Card,
  Avatar,
  Badge,
  styled,
  Snackbar,
  Container,
} from "@mui/material";
import { useSelector } from "react-redux";
import Slide from "react-reveal/Slide";
import { Link } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
function AccountHome() {
  const clientName = useSelector((state) => state.user.clientName);
  const picUrl = useSelector((state) => state.user.picUrl);
  const sex = useSelector((state) => state.user.sex);
  const maritalStatus = useSelector((state) => state.user.maritalStatus);
  const accountStatus = useSelector((state) => state.user.accountStatus);
  const routingNumber = useSelector((state) => state.user.routingNumber);
  const dob = useSelector((state) => state.user.dob);
  const country = useSelector((state) => state.user.country);
  const emailAddress = useSelector((state) => state.user.emailAddress);
  const phoneNumber = useSelector((state) => state.user.phoneNumber);

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Slide left duration={2000}>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        sx={{ mt: 1.5 }}
        className="account"
      >
        <Grid item xs={4} direction={{ xs: "row", sm: "row" }}>
          <Grid xs={12} className="profileInfoWrap">
            <Card
              style={{
                display: "flex",
                // justifyContent: "space-between",
                color: "#344767",
                alignItems: "baseline",
                boxShadow: "none",
                flexDirection: "column",
              }}
            >
              <div className=" profileInfoImg">
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={picUrl}
                    sx={{
                      width: 150,
                      height: 150,
                      border: "5px solid #f1f1f1",
                    }}
                  />
                </StyledBadge>
              </div>

              <div class=" profileInfoContent">
                <h4 style={{ alignItems: "center" }}>
                  {clientName} <MdVerified color="#44b700" />
                </h4>

                <p>{routingNumber}</p>
              </div>
            </Card>
          </Grid>

          <Grid xs={12}>
            <Card
              style={{
                display: "flex",
                // justifyContent: "space-between",
                color: "#344767",
                alignItems: "baseline",
                boxShadow: "none",
                flexDirection: "column",
              }}
            >
              <div className="profileTabMenu">
                <div>
                  <Link to="/account">
                    <span className="profileTab active">
                      <MdAccountCircle className="icon" />
                      <p>My Profile</p>
                    </span>
                  </Link>

                  <Link to="/settings">
                    <span className="profileTab">
                      <MdManageAccounts className="icon" />
                      <p>Edit Account</p>
                    </span>
                  </Link>
                  <Link to="/resetpassword">
                    <span className="profileTab">
                      <MdLock className="icon" />
                      <p>Reset Password</p>
                    </span>
                  </Link>
                  <Link
                    onClick={handleClick({
                      vertical: "top",
                      horizontal: "left",
                    })}
                  >
                    <span className="profileTab">
                      <MdOutlineNoAccounts className="icon" />
                      <p>Disable Account</p>
                    </span>
                  </Link>

                  {/* <span className="profileTab">
                    <MdLogout className="icon" />
                    <p>Logout</p>
                  </span> */}
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert
            onClose={handleClose}
            severity="warning"
            sx={{ width: "100%" }}
          >
            Please Contact Support for this function
          </Alert>
        </Snackbar>
        <Grid item xs={8} className="profile">
          <Card
            sx={{
              //   height: 60 + "vh",
              boxShadow: "none",

              marginBottom: "0px",
            }}
          >
            <Container>
              <h4>My Profile</h4>
            </Container>
            <Container>
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid xs={6}>
                  <span>Full Name</span>
                  <br />
                  <div className="profileInput">
                    <p>{clientName}</p>
                  </div>
                </Grid>
                <Grid xs={6}>
                  <span>Sex</span>
                  <br />
                  <div className="profileInput">
                    <p>{sex}</p>
                  </div>
                </Grid>
              </Grid>
              <br />
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid xs={6}>
                  <span>Email</span>
                  <br />
                  <div className="profileInput">
                    <p>{emailAddress}</p>
                  </div>
                </Grid>
                <Grid xs={6}>
                  <span>Phone Number</span>
                  <br />
                  <div className="profileInput">
                    <p>{phoneNumber}</p>
                  </div>
                </Grid>
              </Grid>

              <br />
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid xs={6}>
                  <span>Country</span>
                  <br />
                  <div className="profileInput">
                    <p>{country}</p>
                  </div>
                </Grid>
                <Grid xs={6}>
                  <span>D.O.B</span>
                  <br />
                  <div className="profileInput">
                    <p>{dob}</p>
                  </div>
                </Grid>
              </Grid>

              <br />
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid xs={6}>
                  <span>Marital Status</span>
                  <br />
                  <div className="profileInput">
                    <p>{maritalStatus}</p>
                  </div>
                </Grid>
                <Grid xs={6}>
                  <span>Identifications</span>
                  <br />
                  <div className="profileInput">
                    <p>Verified</p>
                  </div>
                </Grid>
              </Grid>

              <br />
              <Grid container direction={{ xs: "column", sm: "row" }}>
                <Grid xs={6}>
                  <span>RoutingNumber</span>
                  <br />
                  <div className="profileInput">
                    <p>{routingNumber}</p>
                  </div>
                </Grid>
                <Grid xs={6}>
                  <span>Account Status</span>
                  <br />
                  <div className="profileInput">
                    <p>{accountStatus}</p>
                  </div>
                </Grid>
                <br />
                <br />
              </Grid>
            </Container>
          </Card>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default AccountHome;
