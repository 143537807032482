import React, { PureComponent, useCallback, useState } from "react";
import {
  MdManageAccounts,
  MdLock,
  MdAccountCircle,
  MdOutlineNoAccounts,
} from "react-icons/md";
import "./settings.scss";
import {
  Grid,
  Card,
  Stack,
  Avatar,
  Badge,
  styled,
  TextField,
  Container,
  Button,
  Snackbar,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { BiMedal } from "react-icons/bi";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SettingsHome() {
  const clientName = useSelector((state) => state.user.clientName);
  const maritalStatus = useSelector((state) => state.user.maritalStatus);
  const dob = useSelector((state) => state.user.dob);
  const emailAddress = useSelector((state) => state.user.emailAddress);
  const phoneNumber = useSelector((state) => state.user.phoneNumber);

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Grid
      container
      direction={{ xs: "column", sm: "row" }}
      spacing={2}
      sx={{ mt: 1.5 }}
      className="settings"
    >
      <Grid item xs={4} direction={{ xs: "row", sm: "row" }}>
        <Grid xs={12}>
          <Card
            style={{
              display: "flex",
              // justifyContent: "space-between",
              color: "#344767",
              alignItems: "baseline",
              boxShadow: "none",
              flexDirection: "column",
            }}
          >
            <div className="profileTabMenu">
              <div>
                <Link to="/account">
                  <span className="profileTab ">
                    <MdAccountCircle className="icon" />
                    <p>My Profile</p>
                  </span>
                </Link>

                <Link to="/settings">
                  <span className="profileTab active">
                    <MdManageAccounts className="icon" />
                    <p>Edit Account</p>
                  </span>
                </Link>
                <Link to="/resetpassword">
                  <span className="profileTab">
                    <MdLock className="icon" />
                    <p>Reset Password</p>
                  </span>
                </Link>
                <Link
                  onClick={handleClick({
                    vertical: "top",
                    horizontal: "left",
                  })}
                >
                  <span className="profileTab">
                    <MdOutlineNoAccounts className="icon" />
                    <p>Disable Account</p>
                  </span>
                </Link>
                {/* <span className="profileTab">
                  <MdLogout className="icon" />
                  <p>Logout</p>
                </span> */}
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Please Contact Support for this function
        </Alert>
      </Snackbar>
      <Grid item xs={8} className="profile">
        <Card
          sx={{
            //   height: 60 + "vh",
            boxShadow: "none",

            marginBottom: "0px",
          }}
        >
          <Container>
            <h4>Settings</h4>
          </Container>
          <Container>
            <Grid container direction={{ xs: "column", sm: "row" }}>
              <Grid xs={6}>
                <span>Full Name</span>
                <br />
                <div className="profileInput">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    defaultValue={clientName}
                  />
                </div>
              </Grid>
              <Grid xs={6}>
                <span>Email</span>
                <br />
                <div className="profileInput">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    defaultValue={emailAddress}
                  />
                </div>
              </Grid>
            </Grid>
            <br />
            <Grid container direction={{ xs: "column", sm: "row" }}>
              <Grid xs={6}>
                <span>Marital Status</span>
                <br />
                <div className="profileInput">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    defaultValue={maritalStatus}
                  />
                </div>
              </Grid>
              <Grid xs={6}>
                <span>Phone Number</span>
                <br />
                <div className="profileInput">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    defaultValue={phoneNumber}
                  />
                </div>
              </Grid>
            </Grid>

            <br />
            <Grid container direction={{ xs: "column", sm: "row" }}>
              <Grid xs={6}>
                <span>D.O.B</span>
                <br />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  defaultValue={dob}
                />
              </Grid>
              <Grid xs={6}></Grid>
            </Grid>

            <br />
            <Grid container direction={{ xs: "column", sm: "row" }}>
              <Grid xs={6}>
                <Button variant="contained" color="success" disabled>
                  Update
                </Button>
              </Grid>
            </Grid>

            <br />
          </Container>
        </Card>
      </Grid>
    </Grid>
  );
}

export default SettingsHome;
