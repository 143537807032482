import React, { PureComponent, useEffect, useState } from "react";
import {
  MdVerified,
  MdManageAccounts,
  MdLogout,
  MdLock,
  MdAccountCircle,
  MdOutlineNoAccounts,
} from "react-icons/md";
// import "./account.scss";
import { db, storage } from "../../../firebase";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { query, where, onSnapshot, doc, updateDoc } from "firebase/firestore";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Grid,
  Card,
  Button,
  Avatar,
  Badge,
  styled,
  Container,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Modal,
  Fade,
  Box,
  Backdrop,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

import Slide from "react-reveal/Slide";
import MuiAlert from "@mui/material/Alert";
import { useUserAuth } from "../../../UserAuthContext.js";
import { useParams, Link } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function EditAccountPageHome() {
  let { user } = useUserAuth();
  const [masterAdmin, setMasterAdmin] = useState(false);
  const { id } = useParams();
  const [selectedImages, setSelectedImages] = useState(null);
  const [imageUpload, setImageUpload] = useState(null);
  const [clientName, setClientName] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [password, setPassword] = useState("");
  const [sex, setSex] = useState("");
  const [accountBalance, setAccountBalance] = useState("");
  const [cardBalance, setCardBalance] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [dob, setDob] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [accountStatus, setAccountStatus] = useState("");
  const [docId, setDocid] = useState("");
  const [previousImg, setPreviousImg] = useState("");
  const [block, setBlock] = useState(false);
  const colRef = collection(db, "clientInfo");

  const [lTName, setLtName] = useState(""); //LT means Last transaction
  const [ltDate, setLtDate] = useState("");
  const [ltAmount, setLtAmount] = useState("");
  const [ltType, setLTtype] = useState("");
  const [imfCode, setImfCode] = useState("");
  const handleLTtype = (event) => {
    setLTtype(event.target.value);
  };

  const [stName, setStName] = useState(""); //ST means second  to Last transaction
  const [stDate, setStDate] = useState("");
  const [stAmount, setStAmount] = useState("");
  const [stType, setSTtype] = useState("");
  const handleSTtype = (event) => {
    setSTtype(event.target.value);
  };

  //modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //loading animatiom
  const [loading, setLoading] = useState(false);

  //for sex select button
  const handleSexChange = (event) => {
    setSex(event.target.value);
  };

  //for sex select button
  const handleAccountStatus = (event) => {
    setAccountStatus(event.target.value);
  };
  //for account status select button
  const handleMaritalStatus = (event) => {
    setMaritalStatus(event.target.value);
  };

  const handleBlock = (event) => {
    setBlock(event.target.value);
  };

  const onSelectedFile = (event) => {
    setImageUpload(event.target.files[0]);
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const imageArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectedImages(imageArray);
  };

  useEffect(() => {
    setLoading(true);
    if (user.email === "ceaser@gmail.com") {
      setMasterAdmin(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const editHandler = () => {
    const docSnap = query(colRef, where("routingNumber", "==", id));
    const unsubscribe = onSnapshot(docSnap, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(doc.data().accountStatus);
        setDocid(doc.id);
        setClientName(doc.data().clientName);
        setSex(doc.data().sex);
        setAccountBalance(doc.data().availableBalance);
        setCardBalance(doc.data().cardBalance);
        setErrorMsg(doc.data().errorMsg);
        setEmailAddress(doc.data().emailAddress);
        setPhoneNumber(doc.data().phoneNumber);
        setMaritalStatus(doc.data().maritalStatus);
        setDob(doc.data().dob);
        setCountry(doc.data().country);
        setAccountStatus(doc.data().accountStatus);
        setPassword(doc.data().password);
        setRoutingNumber(doc.data().routingNumber);
        setPreviousImg(doc.data().picUrl);
        setBlock(doc.data().blocked);
        setLtName(doc.data().lTName);
        setLtDate(doc.data().ltDate);
        setLtAmount(doc.data().ltAmount);
        setLTtype(doc.data().ltType);
        setImfCode(doc.data().imfCode);
        setStName(doc.data().stName);
        setStDate(doc.data().stDate);
        setStAmount(doc.data().stAmount);
        setSTtype(doc.data().stType);
      });
    });
  };

  useEffect(() => {
    if (user.email === "ceaser@gmail.com") {
      setMasterAdmin(true);
    }
    editHandler();
  }, []);

  const handleInputUpload = () => {
    const colRef2 = doc(db, "clientInfo", docId);
    if (
      //   imageUrl === "" ||
      clientName === "" ||
      routingNumber === "" ||
      password === "" ||
      sex === "" ||
      accountBalance === "" ||
      cardBalance === "" ||
      emailAddress === "" ||
      phoneNumber === "" ||
      country === "" ||
      dob === "" ||
      maritalStatus === "" ||
      accountStatus === ""
    ) {
      alert("Please insert required fields");
    } else {
      updateDoc(colRef2, {
        clientName: clientName,
        routingNumber: routingNumber,
        password: password,
        sex: sex,
        availableBalance: accountBalance,
        cardBalance: cardBalance,
        errorMsg: errorMsg,
        emailAddress: emailAddress,
        phoneNumber: phoneNumber,
        dob: dob,
        phoneNumber: phoneNumber,
        country: country,
        maritalStatus: maritalStatus,
        accountStatus: accountStatus,
        lTName: lTName,
        ltDate: ltDate,
        ltAmount: ltAmount,
        ltType: ltType,
        stName: stName,
        stDate: stDate,
        stAmount: stAmount,
        stType: stType,
        blocked: block,
        imfCode: imfCode,
      }).then((snapshot) => {
        // console.log(toSend);
        handleOpen();
      });

      setClientName("");
      setSex("");
      setAccountBalance("");
      setCardBalance("");
      setErrorMsg("");
      setEmailAddress("");
      setPhoneNumber("");
      setDob("");
      setMaritalStatus("");
      setCountry("");
      setAccountStatus("");
      setBlock("");
    }
  };

  const handleChangeImgFirebase = (url) => {
    const docRef2 = doc(db, "clientInfo", docId);
    if (url === "") {
      alert("Please insert required fields");
    } else {
      updateDoc(docRef2, {
        picUrl: url,
      }).then((snapshot) => {
        alert("profile changed");
        // window.location.reload();
      });
    }
  };

  const handleChangeImg = async (e) => {
    e.preventDefault();

    const imageRef = ref(storage, `profiles/${imageUpload.name}`);

    const uploadTask = uploadBytes(imageRef, imageUpload).then(() => {
      getDownloadURL(imageRef)
        .then((url) => {
          handleChangeImgFirebase(url);
        })
        .catch((error) => {
          console.log(error.message, "error getting url");
        });
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Tracking Created Successfully</Modal.Title>
        </Modal.Header> */}
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Account Edited successfully
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Login to check
              <Link to="/login">
                <Button variant="contained" color="success" fullWidth>
                  Login
                </Button>
              </Link>
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        sx={{ mt: 1.5 }}
        className="account"
      >
        {masterAdmin ? (
          <Grid item xs={4} direction={{ xs: "row", sm: "row" }}>
            <Grid xs={12} className="profileInfoWrap">
              <Card
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                  color: "#344767",
                  alignItems: "baseline",
                  boxShadow: "none",
                  flexDirection: "column",
                }}
              >
                <div className=" profileInfoImg">
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src={previousImg}
                      sx={{
                        width: 150,
                        height: 150,
                        border: "5px solid #f1f1f1",
                      }}
                    />

                    {selectedImages &&
                      selectedImages.map((image, index) => {
                        return (
                          <Avatar
                            alt="Remy Sharp"
                            src={image}
                            //   {...(image && (src = { image }))}
                            key={image}
                            sx={{
                              width: 150,
                              height: 150,
                              border: "5px solid #f1f1f1",
                            }}
                          />
                        );
                      })}
                  </StyledBadge>
                </div>

                <div className="profileInfoContent">
                  <input
                    type="file"
                    name="images"
                    onChange={onSelectedFile}
                    accept="image/png, image/jpeg, image/webp"
                  />
                </div>
                <br />
                <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  onClick={handleChangeImg}
                >
                  Change Image
                </Button>
              </Card>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={8} className="profile">
          <Card
            sx={{
              //   height: 60 + "vh",
              boxShadow: "none",

              marginBottom: "0px",
            }}
          >
            <Container>
              <h4>My Profile</h4>
            </Container>
            <Container>
              {masterAdmin ? (
                <Grid
                  container
                  direction={{ xs: "column", sm: "row" }}
                  sx={{ flexGrow: 1 }}
                  spacing={3}
                >
                  <Grid item xs={6} md={6}>
                    <span>Full Name</span>
                    <br />
                    <TextField
                      required
                      id="outlined-required"
                      fullWidth
                      value={clientName}
                      onChange={(e) => setClientName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <span>Routing Number</span>
                    <br />
                    <TextField
                      required
                      id="outlined-required"
                      fullWidth
                      helperText="10 digit number"
                      value={routingNumber}
                      onChange={(e) => setRoutingNumber(e.target.value)}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <span>Password</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    helperText="for login"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>Sex</span>
                  <br />
                  <FormControl sx={{ minWidth: 100 }} fullWidth>
                    <Select
                      value={sex}
                      onChange={handleSexChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <span>Account Balance</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    helperText="without $"
                    value={accountBalance}
                    onChange={(e) => setAccountBalance(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>Card Balance</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    helperText="without $"
                    value={cardBalance}
                    onChange={(e) => setCardBalance(e.target.value)}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <span>Error Message</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    helperText="for Billing"
                    value={errorMsg}
                    onChange={(e) => setErrorMsg(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>Email Address</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    // helperText="without $"
                  />
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <span>Phone Number</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    // helperText="for Billing"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>Country </span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    // helperText="without $"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <span>D.O.B</span>
                  <br />
                  <TextField
                    required
                    id="outlined-required"
                    fullWidth
                    helperText="Date of birth"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <span>Marital Status</span>
                  <br />
                  <FormControl sx={{ minWidth: 100 }} fullWidth>
                    <Select
                      value={maritalStatus}
                      onChange={handleMaritalStatus}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={"Not Set"}>Not Set</MenuItem>
                      <MenuItem value={"Single"}>Single</MenuItem>
                      <MenuItem value={"Married"}>Married</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>{" "}
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <span>Account Status</span>
                  <br />
                  <FormControl sx={{ minWidth: 100 }} fullWidth>
                    <Select
                      value={accountStatus}
                      onChange={handleAccountStatus}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={"On Hold"}>On Hold</MenuItem>
                      <MenuItem value={"Active"}>Active</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <br />{" "}
                <Grid item xs={6} md={6}>
                  <span>Transaction History</span>
                  <br />
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Last Transaction</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          required
                          id="outlined-required"
                          fullWidth
                          // helperText="Date of birth"
                          placeholder="Name"
                          value={lTName}
                          onChange={(e) => setLtName(e.target.value)}
                        />{" "}
                        <TextField
                          required
                          id="outlined-required"
                          fullWidth
                          // helperText="Date"
                          value={ltDate}
                          placeholder="Date"
                          onChange={(e) => setLtDate(e.target.value)}
                        />{" "}
                        <TextField
                          required
                          id="outlined-required"
                          fullWidth
                          placeholder="Amount"
                          // helperText="Date of birth"
                          value={ltAmount}
                          onChange={(e) => setLtAmount(e.target.value)}
                        />{" "}
                        <FormControl sx={{ minWidth: 100 }} fullWidth>
                          <Select
                            value={ltType}
                            onChange={handleLTtype}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value={"Credit"}>Credit</MenuItem>
                            <MenuItem value={"Debit"}>Debit</MenuItem>
                            <MenuItem value={"Pending"}>Pending</MenuItem>
                          </Select>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Second to the last Transaction</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          required
                          id="outlined-required"
                          fullWidth
                          // helperText="Date of birth"
                          placeholder="Name"
                          value={stName}
                          onChange={(e) => setStName(e.target.value)}
                        />{" "}
                        <TextField
                          required
                          id="outlined-required"
                          fullWidth
                          // helperText="Date"
                          value={stDate}
                          placeholder="Date"
                          onChange={(e) => setStDate(e.target.value)}
                        />{" "}
                        <TextField
                          required
                          id="outlined-required"
                          fullWidth
                          placeholder="Amount"
                          // helperText="Date of birth"
                          value={stAmount}
                          onChange={(e) => setStAmount(e.target.value)}
                        />{" "}
                        <FormControl sx={{ minWidth: 100 }} fullWidth>
                          <Select
                            value={stType}
                            onChange={handleSTtype}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value={"Credit"}>Credit</MenuItem>
                            <MenuItem value={"Debit"}>Debit</MenuItem>
                            <MenuItem value={"Pending"}>Pending</MenuItem>
                          </Select>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                    {/* <Accordion disabled>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography>Disabled Accordion</Typography>
                    </AccordionSummary>
                  </Accordion> */}
                  </div>
                </Grid>
                {masterAdmin ? (
                  <Grid item xs={6} md={6}>
                    <span>Block</span>
                    <br />
                    <FormControl sx={{ minWidth: 100 }} fullWidth>
                      <Select
                        value={block}
                        onChange={handleBlock}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                      </Select>
                    </FormControl>
                    <span>IMF Code</span>
                    <br />
                    <TextField
                      required
                      id="outlined-required"
                      fullWidth
                      helperText="for Billing"
                      name="IMF Code"
                      value={imfCode}
                      onChange={(e) => {
                        setImfCode(e.target.value);
                      }}
                    />
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
              <br />
              <br />
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                sx={{ flexGrow: 1 }}
                spacing={3}
              >
                <Grid item xs={6} md={6}>
                  <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    onClick={handleInputUpload}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default EditAccountPageHome;
