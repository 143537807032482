import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Sidebar from "../../components/Sidebar.jsx";
import NavBar from "../../components/NavBar.jsx";
import { useSelector } from "react-redux";
import {
  Typography,
  Link,
  Alert,
  Grid,
  Toolbar,
  Drawer,
  CssBaseline,
  Box,
} from "@mui/material";
import CardsHome from "./CardsHome.jsx";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();
  const errorMsg = useSelector((state) => state.user.errorMsg);
  const clientName = useSelector((state) => state.user.clientName);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const blocked = useSelector((state) => state.user.blocked);
  const container =
    window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    // const fetchData2 = () => {
    if (!loggedIn) {
      navigate("/login");
    }

    if (blocked) {
      navigate("/login");
    }
  }, []);

  return (
    <Box sx={{ display: "flex" }} className="dashboard">
      <CssBaseline />
      <NavBar
        handleDrawerToggle={handleDrawerToggle}
        handleCloseNavMenu={handleCloseNavMenu}
        anchorElNav={anchorElNav}
      />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Sidebar handleDrawerToggle={handleDrawerToggle} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Sidebar />
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          //   width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4 className="welcomeText">Hi, {clientName}</h4>
          </Grid>
        </Grid>

        {/* error billing */}
        <Grid container spacing={2}>
          {errorMsg && (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {errorMsg}
              </Alert>
            </Grid>
          )}
        </Grid>
        <br />

        <CardsHome />
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
