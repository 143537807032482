import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    clientName: "",
    cardBalance: "",
    availableBalance: "",
    routingNumber: "",
    accountStatus: "",
    picUrl: "",
    errorMsg: "",
    sex: "",
    emailAddress: "",
    phoneNumber: "",
    country: "",
    dob: "",
    maritalStatus: "",
    loggedIn: false,
    blocked: false,
  },
  reducers: {
    update: (state, action) => {
      state.clientName = action.payload.clientName;
      state.cardBalance = action.payload.cardBalance;
      state.availableBalance = action.payload.availableBalance;
      state.accountStatus = action.payload.accountStatus;
      state.picUrl = action.payload.picUrl;
      state.sex = action.payload.sex;
      state.emailAddress = action.payload.emailAddress;
      state.phoneNumber = action.payload.phoneNumber;
      state.country = action.payload.country;
      state.dob = action.payload.dob;
      state.maritalStatus = action.payload.maritalStatus;
      state.errorMsg = action.payload.errorMsg;
      state.loggedIn = action.payload.loggedIn;
      state.routingNumber = action.payload.routingNumber;
      state.blocked = action.payload.blocked;
    },
    logout: (state) => (state = {}),
  },
});

export const { update, logout } = userSlice.actions;
export default userSlice.reducer;
