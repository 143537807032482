import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA36pmceDyTdI6456LkwJbHMwIQ0vZnhBU",

  authDomain: "chimfinance.firebaseapp.com",

  projectId: "chimfinance",

  storageBucket: "chimfinance.appspot.com",

  messagingSenderId: "715626384124",

  appId: "1:715626384124:web:d00edd021b846bbf96e579",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
export { app, db, storage };
