import React from "react";
import {
  ListItemText,
  ListItemIcon,
  ListItemButton,
  List,
  ListItem,
  Divider,
  Avatar,
  Badge,
  styled,
} from "@mui/material";
import logo from "../assets/gcb.png";
import "./sidebar.scss";
import { logout } from "../context/userSlice.js";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import {
  MdAccountBalance,
  MdCreditCard,
  MdOutlineSocialDistance,
  MdOutlineAreaChart,
  MdPersonOutline,
  MdConstruction,
  MdAttachMoney,
  MdLogout,
} from "react-icons/md";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function Sidebar() {
  const clientName = useSelector((state) => state.user.clientName);
  const picUrl = useSelector((state) => state.user.picUrl);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/");
  };

  return (
    <div style={{ backgroundColor: "#f5f6fa" }}>
      <Link to="/">
        <img
          src={logo}
          className="logo"
          style={{ width: "180px", padding: "24px 20px" }}
        />
      </Link>
      <div
        className="accountInfo"
        // style={{ display: "flex", padding: "16px 20px" }}
        style={{
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <div
          style={{
            alignItems: "center",

            display: "flex",

            justifyContent: "space-around",
            marginLeft: "15px",
          }}
        >
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
          >
            <Avatar
              alt="Remy Sharp"
              src={picUrl}
              sx={{ width: 50, height: 50 }}
            />
          </StyledBadge>
          <div
            style={{
              fontSize: "20px",
              marginLeft: "5px",
            }}
          >
            <h6>{clientName}</h6>
          </div>
        </div>
      </div>

      <br />
      <List>
        <Link to="/dashboard">
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <MdAccountBalance className="icon" />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/transferfunds">
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <MdOutlineSocialDistance className="icon" />
              </ListItemIcon>
              <ListItemText primary="Transfer" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/transactionhistory">
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <MdOutlineAreaChart className="icon" />
              </ListItemIcon>
              <ListItemText primary="History" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/account">
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <MdPersonOutline className="icon" />
              </ListItemIcon>
              <ListItemText primary="Account" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/loans">
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <MdAttachMoney className="icon" />
              </ListItemIcon>
              <ListItemText primary="Loans" />
            </ListItemButton>
          </ListItem>
        </Link>{" "}
        <Link to="/cards">
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <MdCreditCard className="icon" />
              </ListItemIcon>
              <ListItemText primary="Cards" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/settings">
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <MdConstruction className="icon" />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
        </Link>
        <ListItem
          onClick={handleLogout}
          sx={{ color: "#fff", backgroundColor: "#002e6d" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <MdLogout className="icon logout" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
        {/* {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))} */}
      </List>
      <Divider />
    </div>
  );
}

export default Sidebar;
