export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Premium Bank",
  headline: "Unlimited Transactions with zero fees",
  description:
    "Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees.",
  buttonLabel: "Get Started",
  imgStart: true,
  img: require("../img/svg-pig.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: true,
};

export const homeObjtwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Unlimited Access",
  headline: "Login to your account at any time",
  description:
    "We have you covered no matter where you are located. All you need is an internet connection and phone or computer.",
  buttonLabel: "Learn More",
  imgStart: false,
  img: require("../img/svg-1.svg").default,
  alt: "Car",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjthree = {
  id: "signup",
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "We are VERSATILE",
  headline: "We accepts crypto",
  description:
    "We are among the first financial institutes to accept Cryptocurrency as a full legal tender.",
  //   buttonLabel: "Get Started",
  imgStart: true,
  img: require("../img/svg-btc.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: true,
};

export const homeObjfour = {
  //   id: "signup",
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Build your credit Score",
  headline: "A new way to build credit",
  description:
    "Help increase your FICO Score® by an average of 30 points with our new secured credit card.4 No interest, no annual fees, no credit check to apply. Eligibility requirements apply.",
  //   buttonLabel: "Get Started",
  imgStart: true,
  img: require("../img/svg-credit.svg").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: true,
};
