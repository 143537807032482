import React, { useState } from "react";
import {
  Alert,
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useUserAuth } from "../../../UserAuthContext.js";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import emailjs from "@emailjs/browser";
import { db } from "../../../firebase";
import logo from "../../../assets/gcb.png";

const theme = createTheme();

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { signUp } = useUserAuth();
  const navigate = useNavigate();
  const colRef = collection(db, "admins");

  const [toSend, setToSend] = useState({});

  const dbSignup = () => {
    addDoc(colRef, {
      password: password,
      email: email,
      timestamp: Timestamp.now(),
    });
  };

  const sendEmail = (e) => {
    emailjs
      .send(
        "service_nwgx1se",
        "template_u184xcr",
        toSend,
        "user_6ixfEQSmxORDZ3B2xwHU6"
      )
      .then((response) => {})
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signUp(email, password);
      dbSignup();
      sendEmail();
      navigate("/adminlogin");
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="logo" style={{ width: "300px" }} />
          <br /> <br />
          <Typography component="h1" variant="h5">
            Create Admin
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);

                setToSend({
                  ...toSend,
                  [e.target.name]: [e.target.value],
                });
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errorMessage !== "" && (
              <Alert variant="filled" severity="error">
                {errorMessage}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Register Admin
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
