import React from "react";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinkWrapper,
  FooterListItems,
  FooterLinkTitle,
  FooterLink,
} from "./FooterElement";
function Footer() {
  return (
    <>
      <FooterContainer>
        <FooterWrap>
          <FooterLinksContainer>
            <FooterLinkWrapper>
              <FooterListItems>
                <FooterLinkTitle>About Us</FooterLinkTitle>
                <FooterLink to="/login">About Us</FooterLink>
                <FooterLink to="/login">How it works</FooterLink>
                <FooterLink to="/login">Testimonials</FooterLink>
                <FooterLink to="/login">Careers</FooterLink>
                <FooterLink to="/login">Investors</FooterLink>
                <FooterLink to="/adminlogin">Admin</FooterLink>
              </FooterListItems>
              <FooterListItems>
                <FooterLinkTitle>Social Media</FooterLinkTitle>
                <FooterLink to="/login">Instagran</FooterLink>
                <FooterLink to="/login">Facebook</FooterLink>
                <FooterLink to="/login">Testimonials</FooterLink>
                <FooterLink to="/login">Youtube</FooterLink>
                <FooterLink to="/login">Twitter</FooterLink>
                <FooterLink to="/login">Reddit</FooterLink>
              </FooterListItems>
            </FooterLinkWrapper>
            <FooterLinkWrapper>
              <FooterListItems>
                <FooterLinkTitle>Videos</FooterLinkTitle>
                <FooterLink to="/login">Submit VIdeo</FooterLink>
                <FooterLink to="/login">Ambassadors</FooterLink>
                <FooterLink to="/login">Agency</FooterLink>
                <FooterLink to="/login">Influencer</FooterLink>
              </FooterListItems>
              <FooterListItems>
                <FooterLinkTitle>Admin</FooterLinkTitle>
                <FooterLink to="/adminlogin">Admin Login</FooterLink>
                {/* <FooterLink to="/login">How it works</FooterLink>
                <FooterLink to="/login">Testimonials</FooterLink>
                <FooterLink to="/login">Careers</FooterLink>
                <FooterLink to="/login">Investors</FooterLink>
                <FooterLink to="/adminlogin">Admin</FooterLink> */}
              </FooterListItems>
            </FooterLinkWrapper>
          </FooterLinksContainer>
        </FooterWrap>
      </FooterContainer>
    </>
  );
}

export default Footer;
