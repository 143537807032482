import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Button,
  Container,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { faker } from "@faker-js/faker";
import { useSelector } from "react-redux";
import { sample } from "lodash";
import Slide from "react-reveal/Slide";

const columns = [
  { id: "name", label: "Account Name", minWidth: 170 },

  {
    id: "routingNumber",
    label: "Routing Number",
    minWidth: 170,
    align: "right",
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 170,
    align: "right",
  },
  {
    id: "role",
    label: "Role",
    minWidth: 170,
    align: "right",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "right",
  },
];

function createData(name, routingNumber, amount, role, status) {
  return { name, routingNumber, amount, role, status };
}

// const tName = useSelector((state) => state.user.name);

function TransactionHistoryHome() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rows = [...Array(5592)].map((_, index) => ({
    name: faker.name.fullName(),
    routingNumber: faker.finance.routingNumber(),
    amount: sample([
      "$5,400.00",
      "$29,000.00",
      "$400,000.00",
      "$34,390.00",
      "$54,200.00",
      "$4,4320.00",
      "$100,000.00",
      "$10,500.00",
      "$34,390.00",
      "$54,200.00",
      "$7,920.00",
      "$640,000.00",
      "$16,500.00",
      "$38,800.00",
      "$88,620.00",
      "$10,430.00",
      "$10,000.00",
      "$14,500",

      "$8,400.00",
      "$25,000.00",
      "$40,000.00",
      "$30,990.00",
      "$58,770.00",
      "$4,400.00",
      "$1,000.00",
      "$10,200.00",
      "$32,090.00",
      "$5,200.00",
      "$75,320.00",
      "$60,000.00",
      "$164,000.00",
      "$38,300.00",
      "$8,620.00",
      "$1,430.00",
      "$10,000.00",
      "$16,900",
    ]),
    role: faker.finance.transactionType(),
    status: "Completed",
  }));

  return (
    <Slide left duration={2000}>
      <Container sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    //   style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Button variant="outlined" onClick={handleToggle}>
          Request Account Statement
        </Button>
      </Container>
    </Slide>
  );
}

export default TransactionHistoryHome;
