import React, { useEffect, useCallback, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  styled,
  Stack,
  TextField,
  Card,
  Container,
} from "@mui/material";
import PropTypes from "prop-types";
import PersonIcon from "@mui/icons-material/Person";
import PaidIcon from "@mui/icons-material/Paid";
import SendIcon from "@mui/icons-material/Send";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import Slide from "react-reveal/Slide";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import BlockIcon from "@mui/icons-material/Block";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { db, storage } from "../../firebase";
import { query, where, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { addDoc, collection, Timestamp } from "firebase/firestore";
function TransferPageHome() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [iban, setIban] = useState("");
  const [beneficiaryBank, setBeneficiaryBank] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [imfCode, setImfCode] = useState("");
  const [imfCodeInput, setImfCodeInput] = useState("");
  const [antiMoneyLaundering, setAntiMoneyLaundering] = useState("");
  const [taxClearance, setTaxClearance] = useState("");
  const [amount, setAmount] = useState(0);
  const routingNumber = useSelector((state) => state.user.routingNumber);
  const errorMsg = useSelector((state) => state.user.errorMsg);
  const colRef = collection(db, "clientInfo");

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleBackDrop = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
      handleNext();
    }, 4000);
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      let list = [];

      const q = query(colRef, where("routingNumber", "==", routingNumber));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setImfCode(doc.data().imfCode);
          // setLtDate(doc.data().ltDate);
          // setLtAmount(doc.data().ltAmount);
          // setLTtype(doc.data().ltType);

          // setStName(doc.data().stName);
          // setStDate(doc.data().stDate);
          // setStAmount(doc.data().stAmount);
          // setSTtype(doc.data().stType);
        });
      });
    };
    fetchData();
  }, []);

  const steps = ["Beneficiary", "Amount", "Validation", "Send"];

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Box
            noValidate
            sx={{
              mt: 8,
              mb: 6,
            }}
          >
            <TextField
              type="text"
              margin="normal"
              required
              fullWidth
              id="IBAN number"
              label="IBAN number"
              name="IBAN number"
              autoComplete="IBAN number"
              value={iban}
              onChange={(e) => setIban(e.target.value)}
            />
            <TextField
              type="text"
              margin="normal"
              required
              fullWidth
              id="Beneficiary Bank"
              label="Beneficiary Bank"
              name="Beneficiary Bank"
              autoComplete="Beneficiary Bank"
              value={beneficiaryBank}
              onChange={(e) => setBeneficiaryBank(e.target.value)}
            />
            <TextField
              type="text"
              margin="normal"
              required
              fullWidth
              id="Beneficiary Name"
              label="Beneficiary Name"
              name="Beneficiary Name"
              autoComplete="Beneficiary Name"
              value={beneficiaryName}
              onChange={(e) => setBeneficiaryName(e.target.value)}
            />

            {beneficiaryBank !== "" &&
              beneficiaryName !== "" &&
              iban !== "" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              )}
          </Box>
        );
      case 1:
        return (
          <Box
            noValidate
            sx={{
              mt: 8,
              mb: 6,
            }}
          >
            <TextField
              type="text"
              margin="normal"
              required
              fullWidth
              id="Amount"
              label="Amount"
              name="Amount"
              autoComplete="Amount"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <br />
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>

              {amount !== 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBackDrop}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              )}
            </div>
          </Box>
        );
      case 2:
        return (
          <Box
            noValidate
            sx={{
              mt: 8,
              mb: 6,
            }}
          >
            <TextField
              type="text"
              margin="normal"
              required
              fullWidth
              id="IMF Code"
              label="IMF Code"
              name="IMF Code"
              autoComplete="IMF Code"
              // inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              value={imfCodeInput}
              onChange={(e) => setImfCodeInput(e.target.value)}
            />
            <br />
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>

              {imfCodeInput === imfCode && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              )}

              {/* {amount !== 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              )} */}
            </div>
          </Box>
        );
      default:
        return "Unknown step";
    }
  }

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <PersonIcon />,
      2: <PaidIcon />,
      3: <VerifiedUserIcon />,
      4: <SendIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    }),
  }));

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <br />
      <br />
      <Slide left duration={2000}>
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Slide>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <Slide left>
          <div>
            <Typography>{getStepContent(activeStep)}</Typography>
            <div></div>
          </div>
        </Slide>
      )}
    </Box>
  );
}

export default TransferPageHome;
