import React, { useCallback, useState, useEffect } from "react";
import { Stack, Card, Typography, CardContent, Grid, Box } from "@mui/material";
import { BiDollar, BiUser, BiAlbum } from "react-icons/bi";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { useSelector } from "react-redux";
import Slide from "react-reveal/Slide";
import "./dashboardhome.scss";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ResponsiveContainer,
  Sector,
} from "recharts";

import { db, storage } from "../../firebase";
import { query, where, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { addDoc, collection, Timestamp } from "firebase/firestore";

const data = [
  {
    name: "January",
    uv: 590,
    pv: 800,
    amt: 1400,
    cnt: 490,
  },
  {
    name: "February",
    uv: 868,
    pv: 967,
    amt: 1506,
    cnt: 590,
  },
  {
    name: "March",
    uv: 1397,
    pv: 1098,
    amt: 989,
    cnt: 350,
  },
  {
    name: "April",
    uv: 1480,
    pv: 1200,
    amt: 1228,
    cnt: 480,
  },
  {
    name: "May",
    uv: 1520,
    pv: 1108,
    amt: 1100,
    cnt: 460,
  },
  {
    name: "June",
    uv: 1200,
    pv: 1200,
    amt: 1700,
    cnt: 380,
  },
];

function DashBoardHome() {
  const cardBalance = useSelector((state) => state.user.cardBalance);
  const availableBalance = useSelector((state) => state.user.availableBalance);
  const clientName = useSelector((state) => state.user.clientName);
  const accountStatus = useSelector((state) => state.user.accountStatus);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const routingNumber = useSelector((state) => state.user.routingNumber);

  const colRef = collection(db, "clientInfo");

  const [lTName, setLtName] = useState(""); //LT means Last transaction
  const [ltDate, setLtDate] = useState("");
  const [ltAmount, setLtAmount] = useState("");
  const [ltType, setLTtype] = useState("");

  const [stName, setStName] = useState(""); //ST means second  to Last transaction
  const [stDate, setStDate] = useState("");
  const [stAmount, setStAmount] = useState("");
  const [stType, setSTtype] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let list = [];

      const q = query(colRef, where("routingNumber", "==", routingNumber));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setLtName(doc.data().lTName);
          setLtDate(doc.data().ltDate);
          setLtAmount(doc.data().ltAmount);
          setLTtype(doc.data().ltType);

          setStName(doc.data().stName);
          setStDate(doc.data().stDate);
          setStAmount(doc.data().stAmount);
          setSTtype(doc.data().stType);
        });
      });
    };
    fetchData();
  }, []);

  return (
    <div>
      <div>
        <Slide left>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2.5, sm: 2, md: 4 }}
          >
            <Card
              sx={{ height: 100, width: 100 + "%" }}
              className="balanceContainer"
              style={{
                backgroundColor: "rgb(209, 233, 252)",
                color: "rgb(6, 27, 100)",
              }}
            >
              <CardContent>
                <Typography
                  variant="caption"
                  component="div"
                  style={{ marginBottom: "10px" }}
                >
                  Account Balance
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BiDollar style={{ fontSize: "30px" }} />

                  <Typography variant="h5">
                    <b>{availableBalance}</b>
                  </Typography>
                </div>
              </CardContent>
            </Card>

            <Card
              sx={{ height: 100, width: 100 + "%" }}
              className="balanceContainer"
              style={{
                backgroundColor: " rgb(255, 247, 205)",
                color: "rgb(122, 79, 1)",
              }}
            >
              <CardContent>
                <Typography
                  variant="caption"
                  component="div"
                  style={{ marginBottom: "10px" }}
                >
                  Card Balance
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BiDollar style={{ fontSize: "30px" }} />

                  <Typography variant="h5">
                    <b>{cardBalance}</b>
                  </Typography>
                </div>
              </CardContent>
            </Card>

            <Card
              sx={{ height: 100 + "%", width: 100 + "%" }}
              className="balanceContainer"
              style={{
                backgroundColor: "rgb(255, 231, 217)",
                color: "rgb(122, 12, 46)",
              }}
            >
              <CardContent>
                <Typography
                  variant="caption"
                  component="div"
                  style={{ marginBottom: "10px" }}
                >
                  Account Information
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BiUser style={{ fontSize: "body2" }} />
                  &nbsp; &nbsp;
                  <Typography variant="body2">
                    <b>{clientName}</b>
                  </Typography>
                </div>{" "}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AiOutlineFieldNumber style={{ fontSize: "20px" }} />
                  &nbsp; &nbsp;
                  <Typography variant="body2">
                    <b>{routingNumber}</b>
                  </Typography>
                </div>{" "}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BiAlbum style={{ fontSize: "20px" }} />
                  &nbsp; &nbsp;
                  <Typography variant="body2">
                    <b>{accountStatus}</b>
                  </Typography>
                </div>
                {/* <BiDollar style={{ fontSize: "30px" }} />

                <Typography variant="h5">
                  <b>61,832.00</b>
                </Typography> */}
              </CardContent>
            </Card>
          </Stack>
        </Slide>
      </div>
      <Box height={20} />
      <Slide left duration={2000}>
        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          sx={{ mt: 1.5 }}
        >
          {" "}
          <Grid item xs={4}>
            <Grid xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#344767",
                  alignItems: "baseline",
                }}
              >
                <span style={{ fontWeight: "bold" }}> Transactions</span>
                <span style={{ fontSize: "14px" }}>See All</span>
              </div>
            </Grid>
            <br />

            {lTName !== "" && (
              <>
                <Grid xs={12}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ color: "#344767" }}>{lTName}</span>
                      <span style={{ color: "#67748e", fontSize: "14px" }}>
                        {ltDate}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <span
                        style={{
                          color: ltType === "Debit" ? "#f5404c" : "#71d930",
                        }}
                      >
                        {ltAmount}
                      </span>
                      <span
                        style={{
                          color: "#67748e",
                          fontSize: "14px",
                        }}
                      >
                        {ltType}
                      </span>
                    </div>
                  </div>
                </Grid>
                <br />
              </>
            )}

            {stName !== "" && (
              <>
                <Grid xs={12}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ color: "#344767" }}>{stName}</span>
                      <span style={{ color: "#67748e", fontSize: "14px" }}>
                        {stDate}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <span
                        style={{
                          color: stType === "Debit" ? "#f5404c" : "#71d930",
                        }}
                      >
                        {stAmount}
                      </span>
                      <span
                        style={{
                          color: "#67748e",
                          fontSize: "14px",
                        }}
                      >
                        {stType}
                      </span>
                    </div>
                  </div>
                </Grid>
                <br />
              </>
            )}

            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>Sam's Club</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    30 March
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#f5404c" }}>$20.00</span>
                  <span
                    style={{
                      color: "#67748e",
                      fontSize: "14px",
                    }}
                  >
                    Debit
                  </span>
                </div>
              </div>
            </Grid>

            <br />

            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>William Davis</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    05 Feb
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#71d930" }}>$1350.00</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    Credit
                  </span>
                </div>
              </div>
            </Grid>
            <br />
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>Cody Thibeault</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    04 Feb
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#f5404c" }}>$3278.00</span>
                  <span
                    style={{
                      color: "#67748e",
                      fontSize: "14px",
                    }}
                  >
                    Debit
                  </span>
                </div>
              </div>
            </Grid>
            <br />
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>Sue Molloy</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    01 Feb
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#71d930" }}>$102,000.00</span>
                  <span
                    style={{
                      color: "#67748e",
                      fontSize: "14px",
                    }}
                  >
                    Credit
                  </span>
                </div>
              </div>
            </Grid>
            <br />
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>Eloise Reinke</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    30 Jan
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#71d930" }}>$136,800.00</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    credit
                  </span>
                </div>
              </div>
            </Grid>
            <br />
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>Apple Hub</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    30 Jan
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#f5404c" }}>$350.00</span>
                  <span
                    style={{
                      color: "#67748e",
                      fontSize: "14px",
                    }}
                  >
                    Debit
                  </span>
                </div>
              </div>
            </Grid>
            <br />
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>Laurent Perrier</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    25 Jan
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#f5404c" }}>$2550.00</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    Debit
                  </span>
                </div>
              </div>
            </Grid>
            <br />
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>Alexa Liras</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    30 Dec
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#f5404c" }}>$50,000.00</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    Debit
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Card
              sx={{
                height: 60 + "vh",
                boxShadow: "none",
                marginLeft: "20px",
                marginBottom: "0px",
              }}
            >
              <ResponsiveContainer width="90%" height="100%">
                <ComposedChart
                  width={400}
                  height={400}
                  data={data}
                  // margin={{
                  //   top: 20,
                  //   right: 80,
                  //   bottom: 20,
                  //   left: 20,
                  // }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis
                    // dataKey="name"
                    label={{
                      value: "Statistics",
                      position: "insideBottomRight",
                      offset: 0,
                    }}
                    scale="band"
                  />
                  {/* <YAxis
                    label={{
                      value: "Index",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  /> */}
                  <Tooltip />
                  <Legend />
                  <Area
                    type="monotone"
                    dataKey="amt"
                    fill="#d1e9fc"
                    stroke="#8884d8"
                  />
                  <Bar dataKey="pv" barSize={20} fill="#413ea0" />
                  <Line type="monotone" dataKey="uv" stroke="#ff7300" />
                </ComposedChart>
              </ResponsiveContainer>
            </Card>
            {/* <Card sx={{ height: 60 + "vh" }}>
            <CardContent>
             
            </CardContent>
          </Card> */}
          </Grid>
        </Grid>
      </Slide>
    </div>
  );
}

export default DashBoardHome;
