import React from "react";
import "./cards.scss";
import { Grid } from "@mui/material";
import mastercard from "../../assets/mastercard.png";
import { useSelector } from "react-redux";
function CardsHome() {
  const clientName = useSelector((state) => state.user.clientName);
  return (
    <div>
      <Grid container direction={{ xs: "column", sm: "row" }}>
        <Grid sm={6}>
          <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1jxwo5f-MuiPaper-root-MuiCard-root">
            <div className="MuiBox-root css-9ksg9q">
              <div className="MuiBox-root css-irezhe">
                {/* <span
                  className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeDefault css-75zff6-MuiIcon-root"
                  aria-hidden="true"
                >
                  wifi
                </span> */}
              </div>
              <h5 className="MuiTypography-root MuiTypography-h5 css-f03h9h-MuiTypography-root">
                ****&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;7852
              </h5>
              <div className="MuiBox-root css-7qe4b4">
                <div className="MuiBox-root css-1vv3gdn">
                  <div className="MuiBox-root css-1mrsybz">
                    <span
                      className="MuiTypography-root MuiTypography-button css-1m882tz-MuiTypography-root"
                      style={{ fontSize: " 0.875rem", opacity: "0.8" }}
                    >
                      Card Holder
                    </span>
                    <p
                      className="MuiTypography-root MuiTypography-h6 css-1chrc24-MuiTypography-root bb"
                      style={{
                        fontSize: " 1rem",
                        lineHeight: "1.625 ",
                        fontFamily: "Roboto",
                        textTransform: "capitalize",
                      }}
                    >
                      {clientName}
                    </p>
                  </div>
                  <div className="MuiBox-root css-uujcwg">
                    <span
                      className="MuiTypography-root MuiTypography-button css-1m882tz-MuiTypography-root "
                      style={{ fontSize: " 0.875rem", opacity: "0.8" }}
                    >
                      Expires
                    </span>
                    <p
                      className="MuiTypography-root MuiTypography-h6 css-1g2leuz-MuiTypography-root bb"
                      style={{
                        fontSize: " 1rem",
                        lineHeight: "1.625 ",
                        fontFamily: "Roboto",
                        textTransform: "capitalize",
                      }}
                    >
                      11/25
                    </p>
                  </div>
                </div>
                <div className="MuiBox-root css-hxcye6">
                  <img
                    className="MuiBox-root css-14lun21"
                    src={mastercard}
                    alt="master card"
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
        {/* <Grid xs={6}>
          <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1jxwo5f-MuiPaper-root-MuiCard-root">
            <div className="MuiBox-root css-9ksg9q">
              <div className="MuiBox-root css-irezhe">
                <span
                  className="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeDefault css-75zff6-MuiIcon-root"
                  aria-hidden="true"
                >
                  wifi
                </span>
              </div>
              <h5 className="MuiTypography-root MuiTypography-h5 css-f03h9h-MuiTypography-root">
                4562&nbsp;&nbsp;&nbsp;1122&nbsp;&nbsp;&nbsp;4594&nbsp;&nbsp;&nbsp;7852
              </h5>
              <div className="MuiBox-root css-7qe4b4">
                <div className="MuiBox-root css-1vv3gdn">
                  <div className="MuiBox-root css-1mrsybz">
                    <span className="MuiTypography-root MuiTypography-button css-1m882tz-MuiTypography-root">
                      Card Holder
                    </span>
                    <h6 className="MuiTypography-root MuiTypography-h6 css-1chrc24-MuiTypography-root">
                      jack peterson
                    </h6>
                  </div>
                  <div className="MuiBox-root css-uujcwg">
                    <span className="MuiTypography-root MuiTypography-button css-1m882tz-MuiTypography-root">
                      Expires
                    </span>
                    <h6 className="MuiTypography-root MuiTypography-h6 css-1g2leuz-MuiTypography-root">
                      11/22
                    </h6>
                  </div>
                </div>
                <div className="MuiBox-root css-hxcye6">
                  <img
                    className="MuiBox-root css-14lun21"
                    src="Soft%20UI%20Dashboard%20React_files/mastercard.27fca3e7637a9458fb64.png"
                    alt="master card"
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid> */}
      </Grid>
    </div>
  );
}

export default CardsHome;
