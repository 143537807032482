import React, { useEffect, useState, CSSProperties } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { UserAuthContextProvider } from "./UserAuthContext";
import HashLoader from "react-spinners/HashLoader";
import Home from "./pages/home/Home.jsx";
import AdminLogin from "./pages/admin/adminlogin/AdminLogin.jsx";
import DashBoard from "./pages/dashboard/DashBoard.jsx";
import ClientLogin from "./pages/clientlogin/ClientLogin";
import TransferPage from "./pages/transferpage/TransferPage.jsx";
import TransactionHistory from "./pages/transactionhistory/TransactionHistory";
import Account from "./pages/account/Account.jsx";
import LoanPage from "./pages/loans/LoanPage.jsx";
import Settings from "./pages/settings/Settings.jsx";
import ResetPassword from "./pages/resetpassword/ResetPassword.jsx";
import Cards from "./pages/cards/Cards.jsx";
import AdminDashboard from "./pages/admin/admindashboard/AdminDashboard.jsx";
import CreateAccount from "./pages/admin/createAccount/CreateAccount.jsx";
import EditAccount from "./pages/admin/editaccount/EditAccount.jsx";
import EditAccountPage from "./pages/admin/editaccount/EditAccountPage.jsx";
import AdminSignUp from "./pages/admin/adminsignup/AdminSignUp.jsx";

import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";

const override: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100vh",
  marginTop: "auto",
};

function App() {
  const [loading, setLoading] = useState(false);
  const trackingRef = collection(db, "settings");
  const [details, setDetails] = useState([]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  const editHandler = () => {
    const q = query(trackingRef);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setDetails(doc.data());
      });

      // console.log(details);
    });
  };
  useEffect(() => {
    editHandler();
  }, []);

  return (
    <div style={{ backgroundColor: "#f5f6fa" }}>
      {loading ? (
        <HashLoader
          size={50}
          loading={loading}
          color={"#002e6d"}
          cssOverride={override}
        />
      ) : (
        <BrowserRouter>
          <UserAuthContextProvider>
            <Routes>
              <Route path="/" element={<Home />} />

              <Route
                path="/Dashboard"
                element={
                  // <ProtectedRoute>
                  <DashBoard />
                }
              />

              <Route
                path="/admindashboard"
                element={
                  <ProtectedRoute>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/createaccount"
                element={
                  <ProtectedRoute>
                    <CreateAccount />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/editaccounts"
                element={
                  <ProtectedRoute>
                    <EditAccount />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/edit/:id"
                element={
                  <ProtectedRoute>
                    <EditAccountPage />
                  </ProtectedRoute>
                }
              />

              <Route path="/adminlogin" element={<AdminLogin />} />

              <Route path="/login" element={<ClientLogin />} />
              <Route path="/transferfunds" element={<TransferPage />} />
              <Route
                path="/transactionhistory"
                element={<TransactionHistory />}
              />
              <Route path="/account" element={<Account />} />
              <Route path="/loans" element={<LoanPage />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route path="/cards" element={<Cards />} />

              {details.signUpPage !== "false" && (
                <Route path="/adminsignup" element={<AdminSignUp />} />
              )}

              {/* <Route path="*" element={<ErrorPage />} /> */}
            </Routes>
          </UserAuthContextProvider>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
