import React, { PureComponent, useEffect, useState } from "react";
import {
  MdVerified,
  MdManageAccounts,
  MdLogout,
  MdLock,
  MdOutlineDeleteForever,
  MdOutlineMode,
} from "react-icons/md";
// import "./account.scss";
import { db, storage } from "../../../firebase";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";

import {
  Grid,
  Card,
  Button,
  Avatar,
  TableContainer,
  styled,
  Container,
  TextField,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Fade,
  Box,
  Backdrop,
  Typography,
  Table,
  TableBody,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Slide from "react-reveal/Slide";
import { useUserAuth } from "../../../UserAuthContext.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function EditAccountHome() {
  const { user, logOut } = useUserAuth();

  const [clientName, setClientName] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [password, setPassword] = useState("");

  const [modalId, setModalId] = useState([""]);
  const [modalName, setModalName] = useState([""]);
  const [modalTrackingNumber, setModalTrackingNumber] = useState([""]);

  const [data, setData] = useState([]);
  const colRef = collection(db, "clientInfo");

  //modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      console.log(user.email);
      try {
        if (user.email === "ceaser@gmail.com") {
          const q = query(colRef, orderBy("timestamp", "desc"));

          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            list.push({ id: doc.id, info: doc.data() });
          });
          setData(list);
          console.log(list);
        } else {
          const q = query(
            colRef,
            where("admin", "==", user.email),
            orderBy("timestamp", "desc")
          );

          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              list.push({ id: doc.id, info: doc.data() });
            });
            setData(list);
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const docReff = doc(db, "clientInfo", modalId);
    await deleteDoc(docReff);
    handleClose();
    setModalId("");
    setModalName("");
    setModalTrackingNumber("");
    alert("Tracking Deleted");

    window.location.reload();
  };

  return (
    <Slide left duration={2000}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Tracking Created Successfully</Modal.Title>
        </Modal.Header> */}
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Account Created successfully
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Routing Number : {routingNumber}
              <br />
              password : {password}
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        sx={{ mt: 1.5 }}
        className="account"
      >
        <Grid item xs={12} className="profile">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Account Name</StyledTableCell>
                  <StyledTableCell align="right">
                    Available Balance
                  </StyledTableCell>
                  <StyledTableCell align="right">Date</StyledTableCell>

                  {user.email === "ceaser@gmail.com" && (
                    <StyledTableCell align="right">Admin</StyledTableCell>
                  )}

                  <StyledTableCell align="right">Edit</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(({ id, info }) => (
                  <StyledTableRow key={id}>
                    <StyledTableCell component="th" scope="row">
                      {info.clientName}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {info.availableBalance}
                    </StyledTableCell>

                    <StyledTableCell align="right">
                      {" "}
                      {info.timestamp.toDate().toLocaleString("en-GB", {
                        timezone: "UTC",

                        hour12: true,
                      })}
                    </StyledTableCell>

                    {user.email === "ceaser@gmail.com" && (
                      <StyledTableCell align="right">
                        {info.admin}
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="right">
                      {" "}
                      <Button
                        variant="outlined"
                        startIcon={<MdOutlineMode />}
                        onClick={() =>
                          (window.location.href = `/edit/${info.routingNumber}`)
                        }
                      >
                        Edit
                      </Button>{" "}
                      {user.email === "ceaser@gmail.com" && (
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<MdOutlineDeleteForever />}
                          key={id}
                          onClick={() => {
                            setModalId(id);
                            setModalTrackingNumber(info.clientName);
                            setModalName(info.availablebalance);
                            handleOpen();
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Slide>
  );
}

export default EditAccountHome;
