import React from "react";
import SBA_HomepageHero_COVID_OverlayLarge from "./images/SBA_HomepageHero_COVID_OverlayLarge.jpg";
import contractingopt from "./images/contracting-opt.jpg";
import SBA_HomepageHero_COVID_OverlayMedium from "./images/SBA_HomepageHero_COVID_OverlayMedium.jpg";
import SBA_HomepageHero_COVIDBizSmall from "./images/SBA_HomepageHero_COVIDBizSmall.jpg";
import SBA_Disaster_Image from "./images/SBA_Disaster-Image.png";
import CON_SBA_Pandemic_Icon from "./images/CON-SBA_Pandemic_Icon-Left-Aligned.png";
import handshakeopt from "./images/handshake-opt.jpg";
import counseling2opt from "./images/counseling2-opt.jpg";
import acab4f3e92e63b86a62a1cced54b2cf from "./images/0acab4f3e92e63b86a62a1cced54b2cf.png";
import c9620fd495937901a58e3e0 from "./images/1785086d8c9620fd495937901a58e3e0.png";
import CON_SBA_Pandemic_Icon_EIDL from "./images/CON-SBA_Pandemic_Icon-EIDL-update--17-2.png";
import SBA_EIDL_Adv_Icons from "./images/SBA_EIDL_Adv_Icons-11.png";
import a5dfb427c06322d8cf17b35e9 from "./images/482bdb3a5dfb427c06322d8cf17b35e9.png";
import cf3382b91e8d673849888344c351f3 from "./images/65cf3382b91e8d673849888344c351f3.png";
import Background from "./images/Header_image_0.jpg";
function Home() {
  return (
    <div id="main-content" class="_1aFPMUDDNed3fS5tZ6gkRp">
      <div class="_2IPE-QaKQzTMw-RNzXy-g9">
        <div class="_1KZbC4oPdBGBRhLDtJ95zv">
          <div class="_1AF7Q2JyQhcx-m8Rtj4_h-">
            <div class="_1x-N7-J3qjGeAwaIIJxEIK">
              <img
                alt="Two women in masks exchange food at takeout window."
                class="_11w2HT3GQQ2YMxoOvf0cuB"
                src={SBA_HomepageHero_COVID_OverlayLarge}
              />
              <img
                alt="Two women in masks exchange food at takeout window."
                class="_13Z9k6uZemRHSGrGappbyS"
                src={SBA_HomepageHero_COVID_OverlayMedium}
              />
              <img
                alt="Two women in masks exchange food at takeout window."
                class="_3NfBeWVLUxLqZq66S-sxuP"
                src={SBA_HomepageHero_COVIDBizSmall}
              />
            </div>
            <div class="_1rPKRy2ZD6sutGck8riglt">
              <div class="_35IZz-oIGwbUlA2VJEV-NY">
                <h1 class="_31F8knmx9DkV9XHgu8ldcB">
                  Convenient Banking Options
                </h1>
                <div class="_3MRZ4koLS2tpK-2gXA1V_q"></div>
                <a
                  data-testid="button"
                  class="
                        button
                        _2OOFPxXcw28C_AXPt7cAk
                        vX9AS0c9E0DDokFFCak79
                        fuV9-Ulk4_Nska5Osdh-A
                        OzUhTqpKGCg0m1lyzPBeD
                        _1cdYx1okQLdPgrUmGthKK-
                      "
                  target="_self"
                  href="/login"
                >
                  Login
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="_2tgz-B1Gh-yx23QgW-9YKd">
          <div id="cardCollection-0" class="_2OPcBSvSMh3bXtxR64u1bP">
            <div id="paragraph-1">
              <div class="BvJQs6vdF97S8QTsV69Zl">
                <div id="card-row-0-0" class="_1F-FKoNnQ-zg7fXzVx5ez8">
                  <div
                    class="
                          card
                          _3K0VlrD31AIoeZyVoTfWAL
                          _1niW5ZWIoIaV030ebPN2bD
                        "
                    id="card-0-1"
                    data-testid="card"
                    tabindex="0"
                    aria-label="card 1"
                  >
                    <a target="_self" href="/login">
                      <img
                        id="image-0-0"
                        src={CON_SBA_Pandemic_Icon}
                        alt="PPP icon"
                        data-testid="card image"
                      />
                    </a>
                    <div class="content-with-image">
                      <h4
                        class="YOFVa8kI65jPZ47VMhqy0"
                        id="title-0-1"
                        data-testid="card title"
                      >
                        <a target="_self" href="/login">
                          Savings Account
                        </a>
                      </h4>
                      <a data-testid="card link" target="_self" href="/login">
                        Open account now and earn up to 8%{" "}
                      </a>
                    </div>
                  </div>
                  <div
                    class="
                          card
                          _3K0VlrD31AIoeZyVoTfWAL
                          _1niW5ZWIoIaV030ebPN2bD
                        "
                    id="card-0-2"
                    data-testid="card"
                    tabindex="0"
                    aria-label="card 2"
                  >
                    <a target="_self" href="/login">
                      <img
                        id="image-0-1"
                        src={CON_SBA_Pandemic_Icon_EIDL}
                        alt="EIDL icon"
                        data-testid="card image"
                      />
                    </a>
                    <div class="content-with-image">
                      <h4
                        class="YOFVa8kI65jPZ47VMhqy0"
                        id="title-0-2"
                        data-testid="card title"
                      >
                        <a target="_self" href="/login">
                          Current Account
                        </a>
                      </h4>
                      <a data-testid="card link" target="_self" href="/login">
                        Open account now and earn up to 5%
                      </a>
                    </div>
                  </div>
                  <div
                    class="
                          card
                          _3K0VlrD31AIoeZyVoTfWAL
                          _1niW5ZWIoIaV030ebPN2bD
                        "
                    id="card-0-3"
                    data-testid="card"
                    tabindex="0"
                    aria-label="card 3"
                  >
                    <a target="_self" href="/login">
                      <img
                        id="image-0-2"
                        src={SBA_EIDL_Adv_Icons}
                        alt="EIDL Advance icons"
                        data-testid="card image"
                      />
                    </a>
                    <div class="content-with-image">
                      <h4
                        class="YOFVa8kI65jPZ47VMhqy0"
                        id="title-0-3"
                        data-testid="card title"
                      >
                        <a target="_self" href="/login">
                          Fixed Deposit Account
                        </a>
                      </h4>
                      <a data-testid="card link" target="_self" href="/login">
                        Open account now and earn up to 10%
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="_2tgz-B1Gh-yx23QgW-9YKd">
          <div id="callToAction-1" class="_3T1R2xDtoq4hC-NOznDCrS">
            <div id="paragraph-2">
              <div
                data-testid="call-to-action"
                id="call-to-action"
                class="_3xYLGhsgConqZCXVZuxXD dsJDho96YU3I_Gs45jaG3"
              >
                <div
                  data-testid="image"
                  class="_2lZA1LgdBdVoM5iulLX0kc"
                  title="A Native American woman seated at a table wrapping packages"
                  style={{
                    backgroundImage: `url(${Background})`,
                  }}
                ></div>
                <div class="_1pRcOT4GXwYBQETRMvhpUO">
                  <div class="column">
                    <h3 data-testid="headline">
                      Automatically get better deals when you shop online
                    </h3>
                  </div>
                  <div>
                    <div class="_2doJaa6tm9-JRQ8xqJYmEY">
                      <hr
                        class="dash _1EE_NnIijqYRPiIiWlYHdY"
                        style={{ width: "29px" }}
                      />
                    </div>
                    <p data-testid="blurb">
                      GCB Shopping is a free tool that instantly searches for
                      savings and applies them to your cart.
                    </p>
                  </div>
                  <div class="column">
                    <a
                      data-testid="button"
                      class="
                            button
                            _1WOETcJir2ham1r9iMaA12
                            _2OOFPxXcw28C_AXPt7cAk
                            vX9AS0c9E0DDokFFCak79
                            fuV9-Ulk4_Nska5Osdh-A
                            OzUhTqpKGCg0m1lyzPBeD
                            _1cdYx1okQLdPgrUmGthKK-
                          "
                      target="_self"
                      href="/login"
                    >
                      Add GCB Shopping
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="_2tgz-B1Gh-yx23QgW-9YKd">
          <div id="sectionHeader-2" class="_1ORk0rSC4aleN1QT_-2hhx">
            <div id="paragraph-3">
              <h2 id="section-header-2">Your Account In Easy Steps</h2>
            </div>
          </div>
        </div>
        <div class="urfNuKWUnOGw2e3fOAQMQ">
          <div id="panelMenu-3">
            <div id="paragraph-4">
              <div class="_1H0JM10ZISp90FEQQ1Pod2">
                <div>
                  <div
                    id="tile-0"
                    class="_28utukwjLuJdbYH75I-m2r EHhj06HcZVODX16UezPHh"
                  >
                    <a
                      href="javascript:void(0);"
                      class="_1rgvMs7SA4_eryYWQmix2z"
                    >
                      toggle Plan your business menu
                    </a>
                    <div id="tile-0-static" class="_3lAbXIRTRA9eSb89B2GehM">
                      <img
                        id="tile-0-static-icon"
                        class="_1O5WAR7yoVp3JcbQL-BzAG"
                        src={a5dfb427c06322d8cf17b35e9}
                        alt=""
                      />
                      <div
                        id="tile-0-static-title"
                        class="_1Kz82S3UPxvCZhrEV_UZ9c"
                      >
                        <h3 class="_2Ju7tI5BQiBHPB1duE53R9">
                          Consult
                          <br />
                          <span class="FdywhlOOX-a68qHlFoEm2">With Team</span>
                        </h3>
                      </div>
                      <img
                        class="_1vdRrOwf0h2i_Hvc4DRGBH"
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjdweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMjcgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ5ICg1MTAwMikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+YXRvbXMvZ3JhcGhpYy1lbGVtZW50L2NhcmV0L3Njcm9sbDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJhdG9tcy9ncmFwaGljLWVsZW1lbnQvY2FyZXQvc2Nyb2xsIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0ic2Nyb2xsLWFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDguMDAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC0xMy4wMDAwMDAsIC04LjAwMDAwMCkgdHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iNCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMSwwLjA4OTg0Mzg5NTcgTDIzLjA0NTAwNTcsMTIuMTM0ODQ5NiIgaWQ9IlBhdGgtMi1Db3B5IiBzdHJva2U9IiNDQzAwMDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjUwMDAwMCwgNi41ODk4NDQpIHNjYWxlKDEsIC0xKSB0cmFuc2xhdGUoLTE3LjUwMDAwMCwgLTYuNTg5ODQ0KSAiPjwvcGF0aD4KICAgICAgICAgICAgPHBhdGggZD0iTTAsMSBMMTIuOTYxMjY3MywxMy45NjEyNjczIiBpZD0iUGF0aC0yIiBzdHJva2U9IiMwMDJFNkQiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                      />
                      <div class="_2apzedNJMGFPR-MYdRmx2n"></div>
                      <p id="tile-0-static-blurb" class="H6NtnIQBAv4FBjHWmRbC8">
                        Must explain to you how work mistaken give you complete
                        guide they cannot foresee pain.
                      </p>
                    </div>
                  </div>
                  <div
                    id="tile-1"
                    class="_28utukwjLuJdbYH75I-m2r EHhj06HcZVODX16UezPHh"
                  >
                    <a
                      href="javascript:void(0);"
                      class="_1rgvMs7SA4_eryYWQmix2z"
                    >
                      toggle Launch your business menu
                    </a>
                    <div id="tile-1-static" class="_3lAbXIRTRA9eSb89B2GehM">
                      <img
                        id="tile-1-static-icon"
                        class="_1O5WAR7yoVp3JcbQL-BzAG"
                        src={cf3382b91e8d673849888344c351f3}
                        alt=""
                      />
                      <div
                        id="tile-1-static-title"
                        class="_1Kz82S3UPxvCZhrEV_UZ9c"
                      >
                        <h3 class="_2Ju7tI5BQiBHPB1duE53R9">
                          KYC
                          <br />
                          <span class="FdywhlOOX-a68qHlFoEm2">
                            Verification
                          </span>
                        </h3>
                      </div>
                      <img
                        class="_1vdRrOwf0h2i_Hvc4DRGBH"
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjdweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMjcgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ5ICg1MTAwMikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+YXRvbXMvZ3JhcGhpYy1lbGVtZW50L2NhcmV0L3Njcm9sbDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJhdG9tcy9ncmFwaGljLWVsZW1lbnQvY2FyZXQvc2Nyb2xsIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0ic2Nyb2xsLWFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDguMDAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC0xMy4wMDAwMDAsIC04LjAwMDAwMCkgdHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iNCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMSwwLjA4OTg0Mzg5NTcgTDIzLjA0NTAwNTcsMTIuMTM0ODQ5NiIgaWQ9IlBhdGgtMi1Db3B5IiBzdHJva2U9IiNDQzAwMDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjUwMDAwMCwgNi41ODk4NDQpIHNjYWxlKDEsIC0xKSB0cmFuc2xhdGUoLTE3LjUwMDAwMCwgLTYuNTg5ODQ0KSAiPjwvcGF0aD4KICAgICAgICAgICAgPHBhdGggZD0iTTAsMSBMMTIuOTYxMjY3MywxMy45NjEyNjczIiBpZD0iUGF0aC0yIiBzdHJva2U9IiMwMDJFNkQiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                      />
                      <div class="_2apzedNJMGFPR-MYdRmx2n"></div>
                      <p id="tile-1-static-blurb" class="H6NtnIQBAv4FBjHWmRbC8">
                        Business it will frequently occur that pleasures have to
                        be repudiated and annoyances accepted.
                      </p>
                    </div>
                  </div>
                  {/* <div
                    id="tile-2"
                    class="_28utukwjLuJdbYH75I-m2r EHhj06HcZVODX16UezPHh"
                  >
                    <a
                      href="javascript:void(0);"
                      class="_1rgvMs7SA4_eryYWQmix2z"
                    >
                      toggle Manage your business menu
                    </a>
                    <div id="tile-2-static" class="_3lAbXIRTRA9eSb89B2GehM">
                      <img
                        id="tile-2-static-icon"
                        class="_1O5WAR7yoVp3JcbQL-BzAG"
                        src={c9620fd495937901a58e3e0}
                        alt=""
                      />
                      <div
                        id="tile-2-static-title"
                        class="_1Kz82S3UPxvCZhrEV_UZ9c"
                      >
                        <h3 class="_2Ju7tI5BQiBHPB1duE53R9">
                          Manage
                          <br />
                          <span class="FdywhlOOX-a68qHlFoEm2">
                            your business
                          </span>
                        </h3>
                      </div>
                      <img
                        class="_1vdRrOwf0h2i_Hvc4DRGBH"
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjdweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMjcgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ5ICg1MTAwMikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+YXRvbXMvZ3JhcGhpYy1lbGVtZW50L2NhcmV0L3Njcm9sbDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJhdG9tcy9ncmFwaGljLWVsZW1lbnQvY2FyZXQvc2Nyb2xsIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0ic2Nyb2xsLWFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDguMDAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC0xMy4wMDAwMDAsIC04LjAwMDAwMCkgdHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iNCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMSwwLjA4OTg0Mzg5NTcgTDIzLjA0NTAwNTcsMTIuMTM0ODQ5NiIgaWQ9IlBhdGgtMi1Db3B5IiBzdHJva2U9IiNDQzAwMDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjUwMDAwMCwgNi41ODk4NDQpIHNjYWxlKDEsIC0xKSB0cmFuc2xhdGUoLTE3LjUwMDAwMCwgLTYuNTg5ODQ0KSAiPjwvcGF0aD4KICAgICAgICAgICAgPHBhdGggZD0iTTAsMSBMMTIuOTYxMjY3MywxMy45NjEyNjczIiBpZD0iUGF0aC0yIiBzdHJva2U9IiMwMDJFNkQiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                      />
                      <div class="_2apzedNJMGFPR-MYdRmx2n"></div>
                      <p id="tile-2-static-blurb" class="H6NtnIQBAv4FBjHWmRbC8">
                        Run your business like a boss. Master day-to-day
                        operations and prepare for success.
                      </p>
                    </div>
                  </div> */}
                  <div
                    id="tile-3"
                    class="_28utukwjLuJdbYH75I-m2r EHhj06HcZVODX16UezPHh"
                  >
                    <a
                      href="javascript:void(0);"
                      class="_1rgvMs7SA4_eryYWQmix2z"
                    >
                      toggle Grow your business menu
                    </a>
                    <div id="tile-3-static" class="_3lAbXIRTRA9eSb89B2GehM">
                      <img
                        id="tile-3-static-icon"
                        class="_1O5WAR7yoVp3JcbQL-BzAG"
                        src={acab4f3e92e63b86a62a1cced54b2cf}
                        alt=""
                      />
                      <div
                        id="tile-3-static-title"
                        class="_1Kz82S3UPxvCZhrEV_UZ9c"
                      >
                        <h3 class="_2Ju7tI5BQiBHPB1duE53R9">
                          Start
                          <br />
                          <span class="FdywhlOOX-a68qHlFoEm2">
                            Your Savings
                          </span>
                        </h3>
                      </div>
                      <img
                        class="_1vdRrOwf0h2i_Hvc4DRGBH"
                        alt=""
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjdweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMjcgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ5ICg1MTAwMikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+YXRvbXMvZ3JhcGhpYy1lbGVtZW50L2NhcmV0L3Njcm9sbDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJhdG9tcy9ncmFwaGljLWVsZW1lbnQvY2FyZXQvc2Nyb2xsIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0ic2Nyb2xsLWFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMy4wMDAwMDAsIDguMDAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC0xMy4wMDAwMDAsIC04LjAwMDAwMCkgdHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiIHN0cm9rZS13aWR0aD0iNCI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMSwwLjA4OTg0Mzg5NTcgTDIzLjA0NTAwNTcsMTIuMTM0ODQ5NiIgaWQ9IlBhdGgtMi1Db3B5IiBzdHJva2U9IiNDQzAwMDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3LjUwMDAwMCwgNi41ODk4NDQpIHNjYWxlKDEsIC0xKSB0cmFuc2xhdGUoLTE3LjUwMDAwMCwgLTYuNTg5ODQ0KSAiPjwvcGF0aD4KICAgICAgICAgICAgPHBhdGggZD0iTTAsMSBMMTIuOTYxMjY3MywxMy45NjEyNjczIiBpZD0iUGF0aC0yIiBzdHJva2U9IiMwMDJFNkQiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
                      />
                      <div class="_2apzedNJMGFPR-MYdRmx2n"></div>
                      <p id="tile-3-static-blurb" class="H6NtnIQBAv4FBjHWmRbC8">
                        Being able to do what we like best every pleasure is to
                        be welcomed and pain avoided but in certain.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <br />
        <div class="_2tgz-B1Gh-yx23QgW-9YKd">
          <div id="callToAction-1" class="_3T1R2xDtoq4hC-NOznDCrS">
            <div id="paragraph-2">
              <div
                data-testid="call-to-action"
                id="call-to-action"
                class="_3xYLGhsgConqZCXVZuxXD dsJDho96YU3I_Gs45jaG3"
              >
                <div class="_1pRcOT4GXwYBQETRMvhpUO">
                  <div class="column">
                    <h3 data-testid="headline">
                      Put CreditWise in your pocket
                    </h3>
                  </div>
                  <div>
                    <div class="_2doJaa6tm9-JRQ8xqJYmEY">
                      <hr
                        class="dash _1EE_NnIijqYRPiIiWlYHdY"
                        style={{ width: "29px" }}
                      />
                    </div>
                    <p data-testid="blurb">
                      Get help building your credit score and financial future
                      with our free app.
                    </p>
                  </div>
                  <div class="column">
                    <a
                      data-testid="button"
                      class="
                            button
                            _1WOETcJir2ham1r9iMaA12
                            _2OOFPxXcw28C_AXPt7cAk
                            vX9AS0c9E0DDokFFCak79
                            fuV9-Ulk4_Nska5Osdh-A
                            OzUhTqpKGCg0m1lyzPBeD
                            _1cdYx1okQLdPgrUmGthKK-
                          "
                      target="_self"
                      href="/login"
                    >
                      Access resources
                    </a>
                  </div>
                </div>{" "}
                <div
                  data-testid="image"
                  class="_2lZA1LgdBdVoM5iulLX0kc"
                  title="A Native American woman seated at a table wrapping packages"
                  style={{
                    backgroundImage: `url(${Background})`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="_2tgz-B1Gh-yx23QgW-9YKd">
          <div id="sectionHeader-4" class="_1ORk0rSC4aleN1QT_-2hhx">
            <div id="paragraph-5">
              <h2 id="section-header-4">How we help small businesses</h2>
            </div>
          </div>
        </div>
        <div class="_2tgz-B1Gh-yx23QgW-9YKd">
          <div id="cardCollection-5" class="_2OPcBSvSMh3bXtxR64u1bP">
            <div id="paragraph-6">
              <div class="BvJQs6vdF97S8QTsV69Zl">
                <div id="card-row-5-0" class="_1F-FKoNnQ-zg7fXzVx5ez8">
                  <div
                    class="
                          card
                          _3K0VlrD31AIoeZyVoTfWAL
                          _3TEjCkviI2lxOtzV0ThlWC
                        "
                    id="card-5-1"
                    data-testid="card"
                    tabindex="0"
                    aria-label="card 1"
                  >
                    <a target="_self" href="/login">
                      <img
                        id="image-5-0"
                        src={counseling2opt}
                        alt="People in business counseling session"
                        data-testid="card image"
                      />
                    </a>
                    <div class="content-with-image">
                      <h4
                        class="YOFVa8kI65jPZ47VMhqy0"
                        id="title-5-1"
                        data-testid="card title"
                      >
                        <a target="_self" href="/login">
                          Free Federal Grants
                        </a>
                      </h4>
                      <div>
                        <div class="_2doJaa6tm9-JRQ8xqJYmEY">
                          <hr
                            id="hr-5-0"
                            class="dash _1EE_NnIijqYRPiIiWlYHdY"
                            style={{ width: "30px" }}
                          />
                        </div>
                        <p
                          id="subtitle-text-5-0"
                          data-testid="card subtitle text"
                          tabindex="0"
                        >
                          Get Federal relief grants to keep yourself going.
                          <a
                            aria-label="Read more"
                            target="_self"
                            href="/login"
                          >
                            <i class="fa fa-angle-right"></i>
                          </a>
                        </p>
                      </div>
                      <a data-testid="card link" target="_self" href="/login">
                        Sign up
                      </a>
                    </div>
                  </div>
                  <div
                    class="
                          card
                          _3K0VlrD31AIoeZyVoTfWAL
                          _3TEjCkviI2lxOtzV0ThlWC
                        "
                    id="card-5-2"
                    data-testid="card"
                    tabindex="0"
                    aria-label="card 2"
                  >
                    <a target="_self" href="/login">
                      <img
                        id="image-5-1"
                        src={handshakeopt}
                        alt="People shaking hands"
                        data-testid="card image"
                      />
                    </a>
                    <div class="content-with-image">
                      <h4
                        class="YOFVa8kI65jPZ47VMhqy0"
                        id="title-5-2"
                        data-testid="card title"
                      >
                        <a target="_self" href="/login">
                          SBA-guaranteed business Grant interest-free
                        </a>
                      </h4>
                      <div>
                        <div class="_2doJaa6tm9-JRQ8xqJYmEY">
                          <hr
                            id="hr-5-1"
                            class="dash _1EE_NnIijqYRPiIiWlYHdY"
                            style={{ width: "30px" }}
                          />
                        </div>
                        <p
                          id="subtitle-text-5-1"
                          data-testid="card subtitle text"
                          tabindex="0"
                        >
                          Find an SBA Grant near you to help fund your business.
                          <a
                            aria-label="Read more"
                            target="_self"
                            href="/login"
                          >
                            <i class="fa fa-angle-right"></i>
                          </a>
                        </p>
                      </div>
                      <a data-testid="card link" target="_self" href="/login">
                        Use Lender Match
                      </a>
                    </div>
                  </div>
                  <div
                    class="
                          card
                          _3K0VlrD31AIoeZyVoTfWAL
                          _3TEjCkviI2lxOtzV0ThlWC
                        "
                    id="card-5-3"
                    data-testid="card"
                    tabindex="0"
                    aria-label="card 3"
                  >
                    <a target="_self" href="/login">
                      <img
                        id="image-5-2"
                        src={SBA_Disaster_Image}
                        alt="Flooded two-lane highway"
                        data-testid="card image"
                      />
                    </a>
                    <div class="content-with-image">
                      <h4
                        class="YOFVa8kI65jPZ47VMhqy0"
                        id="title-5-3"
                        data-testid="card title"
                      >
                        <a target="_self" href="/signupe">
                          Home &amp; business disaster Grant
                        </a>
                      </h4>
                      <div>
                        <div class="_2doJaa6tm9-JRQ8xqJYmEY">
                          <hr
                            id="hr-5-2"
                            class="dash _1EE_NnIijqYRPiIiWlYHdY"
                            style={{ width: "30px" }}
                          />
                        </div>
                        <p
                          id="subtitle-text-5-2"
                          data-testid="card subtitle text"
                          tabindex="0"
                        >
                          Apply for a low-interest disaster Grant to help
                          recover from declared disasters.
                          <a
                            aria-label="Read more"
                            target="_self"
                            href="/login"
                          >
                            <i class="fa fa-angle-right"></i>
                          </a>
                        </p>
                      </div>
                      <a data-testid="card link" target="_self" href="/login">
                        Apply for assistance
                      </a>
                    </div>
                  </div>
                  <div
                    class="
                          card
                          _3K0VlrD31AIoeZyVoTfWAL
                          _3TEjCkviI2lxOtzV0ThlWC
                        "
                    id="card-5-4"
                    data-testid="card"
                    tabindex="0"
                    aria-label="card 4"
                  >
                    <a target="_self" href="/login">
                      <img
                        id="image-5-3"
                        src={contractingopt}
                        alt="United States Capitol"
                        data-testid="card image"
                      />
                    </a>
                    <div class="content-with-image">
                      <h4
                        class="YOFVa8kI65jPZ47VMhqy0"
                        id="title-5-4"
                        data-testid="card title"
                      >
                        <a target="_self" href="/login">
                          Federal government contracting
                        </a>
                      </h4>
                      <div>
                        <div class="_2doJaa6tm9-JRQ8xqJYmEY">
                          <hr
                            id="hr-5-3"
                            class="dash _1EE_NnIijqYRPiIiWlYHdY"
                            style={{ width: "30px" }}
                          />
                        </div>
                        <p
                          id="subtitle-text-5-3"
                          data-testid="card subtitle text"
                          tabindex="0"
                        >
                          Learn how to find and win small business government
                          contracts.
                          <a
                            aria-label="Read more"
                            target="_self"
                            href="/login"
                          >
                            <i class="fa fa-angle-right"></i>
                          </a>
                        </p>
                      </div>
                      <a data-testid="card link" target="_self" href="/login">
                        Find contracts
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="_2tgz-B1Gh-yx23QgW-9YKd">
          <div id="callToAction-6" class="_3T1R2xDtoq4hC-NOznDCrS">
            <div id="paragraph-7">
              <div
                data-testid="call-to-action"
                id="call-to-action"
                class="_3xYLGhsgConqZCXVZuxXD AB9FzqXhXYIUL7ZoxCyTR"
              >
                <div class="_1pRcOT4GXwYBQETRMvhpUO">
                  <div class="column">
                    <h3 data-testid="headline">
                      Experience a New Digital World.
                    </h3>
                  </div>
                  <div>
                    <div class="_2doJaa6tm9-JRQ8xqJYmEY">
                      <hr
                        class="dash _1EE_NnIijqYRPiIiWlYHdY"
                        style={{ width: "29px" }}
                      />
                    </div>
                    <p data-testid="blurb">
                      Mobile banking application with new & exciting features.
                    </p>
                  </div>
                  <div class="column">
                    <a
                      data-testid="button"
                      class="
                            button
                            _1WOETcJir2ham1r9iMaA12
                            _2OOFPxXcw28C_AXPt7cAk
                            vX9AS0c9E0DDokFFCak79
                            fuV9-Ulk4_Nska5Osdh-A
                            OzUhTqpKGCg0m1lyzPBeD
                            _1cdYx1okQLdPgrUmGthKK-
                          "
                      target="_self"
                      href="/login"
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
