import React from "react";
import {
  ListItemText,
  ListItemIcon,
  ListItemButton,
  List,
  ListItem,
  Divider,
  Avatar,
  Badge,
  styled,
} from "@mui/material";
import logo from "../../../assets/gcb.png";
import "./sidebar.scss";

import { useNavigate } from "react-router-dom";

import {
  MdAdminPanelSettings,
  MdPersonAddAlt,
  MdOutlineManageAccounts,
  MdOutlineNoAccounts,
} from "react-icons/md";
import { Link } from "react-router-dom";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function AdminSidebar({ user }) {
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: "#f5f6fa" }}>
      {/* <Toolbar /> */}
      <img
        src={logo}
        className="logo"
        style={{ width: "200px", padding: "24px 20px" }}
      />
      <div
        className="accountInfo"
        // style={{ display: "flex", padding: "16px 20px" }}
        style={{
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <div
          style={{
            alignItems: "center",

            marginLeft: "15px",
          }}
        >
          {/* <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
          >
            <Avatar
              alt="Remy Sharp"
              src={picUrl}
              sx={{ width: 50, height: 50 }}
            />
          </StyledBadge> */}
          <div
            style={{
              fontSize: "20px",
              marginLeft: "5px",
            }}
          >
            <h6>{user}</h6>
          </div>
        </div>
      </div>

      <br />
      <List>
        <Link to="/admindashboard">
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <MdAdminPanelSettings className="icon" />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/createaccount">
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <MdPersonAddAlt className="icon" />
              </ListItemIcon>
              <ListItemText primary="New Account" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/editaccounts">
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <MdOutlineManageAccounts className="icon" />
              </ListItemIcon>
              <ListItemText primary="Edit Accounts" />
            </ListItemButton>
          </ListItem>
        </Link>
        {/* <Link to="/deleteaccounts"> */}
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <MdOutlineNoAccounts className="icon" />
            </ListItemIcon>
            <ListItemText primary="Delete Accounts" />
          </ListItemButton>
        </ListItem>
        {/* </Link> */}
        {/* <ListItem
          // onClick={handleLogout}
          sx={{ color: "#fff", backgroundColor: "#216725" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <MdLogout className="icon logout" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem> */}
      </List>
      <Divider />
    </div>
  );
}

export default AdminSidebar;
