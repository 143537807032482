import React, { useEffect, useState } from "react";
import {
  Stack,
  Card,
  Typography,
  CardContent,
  MenuItem,
  FormControl,
  Select,
  Box,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import Slide from "react-reveal/Slide";
import { db } from "../../../firebase";
import { useUserAuth } from "../../../UserAuthContext.js";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
function AdminDashboardHome() {
  const { user, logOut } = useUserAuth();
  const [data, setData] = useState([]);
  const accountRef = collection(db, "settings");
  const [docId, setDocid] = useState("");
  const [masterAdmin, setMasterAdmin] = useState(false);
  const [adminSignUp, setAdminSignUp] = useState("");
  const NoOfAccount = collection(db, "clientInfo");

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      // console.log(user.email);
      try {
        if (user.email === "ceaser@gmail.com") {
          const q = query(NoOfAccount);
          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              list.push({ id: doc.id, info: doc.data() });
            });
            setData(list);
            // console.log(list.length);
          });
        } else {
          const q = query(NoOfAccount, where("admin", "==", user.email));

          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              list.push({ id: doc.id, info: doc.data() });
            });
            setData(list);
            // console.log(list.length);
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [data]);

  useEffect(() => {
    if (user.email === "ceaser@gmail.com") {
      setMasterAdmin(true);
    }

    editHandler();
  }, []);

  //for sex select button
  const handleAdminSignUp = (event) => {
    setAdminSignUp(event.target.value);
  };

  const editHandler = () => {
    const docSnap = query(accountRef);
    const unsubscribe = onSnapshot(docSnap, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setDocid(doc.id);
        setAdminSignUp(doc.data().signUpPage);
      });
    });
  };

  const handleInputUpload = () => {
    const colRef2 = doc(db, "settings", docId);
    updateDoc(colRef2, {
      signUpPage: adminSignUp,
    }).then((snapshot) => {});
  };

  return (
    <div>
      <div>
        <Slide left>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2.5, sm: 2, md: 4 }}
          >
            <Card
              sx={{ height: 100, width: 100 + "%" }}
              className="balanceContainer"
              style={{
                backgroundColor: "rgb(209, 233, 252)",
                color: "rgb(6, 27, 100)",
              }}
            >
              <CardContent>
                <Typography
                  variant="caption"
                  component="div"
                  style={{ marginBottom: "10px" }}
                >
                  No of accounts created
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h5">
                    <b>{data.length}</b>
                  </Typography>
                </div>
              </CardContent>
            </Card>

            {masterAdmin ? (
              <Card
                sx={{ height: 100 + "%", width: 100 + "%" }}
                className="balanceContainer"
                style={{
                  backgroundColor: "rgb(209, 233, 252)",
                  color: "rgb(6, 27, 100)",
                }}
              >
                <CardContent>
                  <Typography
                    variant="caption"
                    component="div"
                    style={{ marginBottom: "10px" }}
                  >
                    Admin SignUp
                  </Typography>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormControl sx={{ minWidth: 100 }} fullWidth>
                      <Select
                        value={adminSignUp}
                        onChange={handleAdminSignUp}
                        displayEmpty
                        // inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value={"true"}>True</MenuItem>
                        <MenuItem value={"false"}>False</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <br />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleInputUpload}
                    >
                      Update
                    </Button>
                  </div>
                </CardContent>
              </Card>
            ) : (
              ""
            )}
          </Stack>
        </Slide>
      </div>
      <Box height={20} />
      {/* <Slide left duration={2000}>
        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          sx={{ mt: 1.5 }}
        >
          {" "}
          <Grid item xs={4}>
         
            <Grid xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#344767",
                  alignItems: "baseline",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Transactions</span>
                <span style={{ fontSize: "14px" }}>See All</span>
              </div>
            </Grid>
            <br />
         
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>William Davis</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    05 Feb
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#71d930" }}>$1350.00</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    Credit
                  </span>
                </div>
              </div>
            </Grid>
            <br />
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>Cody Thibeault</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    04 Feb
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#f5404c" }}>$3278.00</span>
                  <span
                    style={{
                      color: "#67748e",
                      fontSize: "14px",
                    }}
                  >
                    Debit
                  </span>
                </div>
              </div>
            </Grid>
            <br />
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>Sue Molloy</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    01 Feb
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#71d930" }}>$102,000.00</span>
                  <span
                    style={{
                      color: "#67748e",
                      fontSize: "14px",
                    }}
                  >
                    Credit
                  </span>
                </div>
              </div>
            </Grid>
            <br />
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>Eloise Reinke</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    30 Jan
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#71d930" }}>$136,800.00</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    credit
                  </span>
                </div>
              </div>
            </Grid>
            <br />
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>Apple Hub</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    30 Jan
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#f5404c" }}>$350.00</span>
                  <span
                    style={{
                      color: "#67748e",
                      fontSize: "14px",
                    }}
                  >
                    Debit
                  </span>
                </div>
              </div>
            </Grid>
            <br />
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>Laurent Perrier</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    25 Jan
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#f5404c" }}>$2550.00</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    Debit
                  </span>
                </div>
              </div>
            </Grid>
            <br />
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "#344767" }}>Alexa Liras</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    30 Dec
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span style={{ color: "#f5404c" }}>$50,000.00</span>
                  <span style={{ color: "#67748e", fontSize: "14px" }}>
                    Debit
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Card
              sx={{
                height: 60 + "vh",
                boxShadow: "none",
                marginLeft: "20px",
                marginBottom: "0px",
              }}
            >
              <ResponsiveContainer width="90%" height="100%">
                <ComposedChart
                  width={400}
                  height={400}
                  data={data}
                
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis
                   
                    label={{
                      value: "Statistics",
                      position: "insideBottomRight",
                      offset: 0,
                    }}
                    scale="band"
                  />
                
                  <Tooltip />
                  <Legend />
                  <Area
                    type="monotone"
                    dataKey="amt"
                    fill="#d1e9fc"
                    stroke="#8884d8"
                  />
                  <Bar dataKey="pv" barSize={20} fill="#413ea0" />
                  <Line type="monotone" dataKey="uv" stroke="#ff7300" />
                </ComposedChart>
              </ResponsiveContainer>
            </Card>
          
          </Grid>
        </Grid>
      </Slide> */}
    </div>
  );
}

export default AdminDashboardHome;
